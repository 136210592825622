import { motion } from "framer-motion"
import styled from "styled-components"

export const MainButton = styled(motion.button)`
    background: ${props => (props.dark ? "black" : "white")};
    color: ${props => (props.dark ? "white" : "black")};
    text-align: center;
    text-transform: uppercase;
    font-size: ${props => (props.small ? "12px" : "14px")};
    border-radius: 4px;
    padding: 15px 30px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.2s ease;

    :focus {
        outline: none;
    }

    :disabled {
        background: #707070;
    }

    @media (max-width: 550px) {
        font-size: 12px;
        padding: 12px 25px;
    }
`

export const OutlineButton = styled(motion.button)`
    border: ${props => (props.dark ? "1px solid black" : "1px solid white")};
    color: ${props => (props.dark ? "black" : "white")};
    text-align: center;
    text-transform: uppercase;
    padding: ${props => (props.small ? "12px 25px" : "15px 35px")};
    font-size: ${props => (props.small ? "12px" : "14px")};
    outline: none;
    cursor: pointer;
    transition: 0.2s ease-out;
    background: none;
    min-width: 150px;

    :hover {
        background: ${props => (props.dark ? "black" : "white")};
        color: ${props => (props.dark ? "white" : "black")};
    }

    :focus {
        outline: none;
    }

    :disabled {
        /* background: #707070; */
        border: 1px solid #707070;
        color: #707070;
    }

    @media (max-width: 550px) {
        font-size: 12px;
        padding: 12px 25px;

    }
`
export const TextButton = styled.button`
    border: none;
    outline: none;
    color: black;
    text-align: center;
    /* padding: ${props => (props.small ? "12px 25px" : "15px 35px")}; */
    font-size: ${props => (props.small ? "12px" : "16px")};
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s ease-out;
    background: none;
    min-width: 150px;


    :focus {
        outline: none;
    }

    :disabled {
        /* background: #707070; */
    }

    @media (max-width: 550px) {
        font-size: 12px;
        padding: 12px 25px;

    }
`
