import { navigate } from "gatsby"
import React from "react"
import { useSetRecoilState } from "recoil"
import styled from "styled-components"
import { Flex } from "../layout/reusables"
import { sidebarOpen } from "../state/atoms"
import { Heading } from "../styles/typography"
import moment from "moment"

const ResourceItem = ({ item }) => {
    const toggleSidebar = useSetRecoilState(sidebarOpen)
    return (
        <>
            <Container
                onClick={() => {
                    navigate(`/`, {
                        state: { content: item },
                    })
                    toggleSidebar(false)
                }}
            >
                <Title>{item.title}</Title>
                <Type>{item.type}</Type>
                <Description>{item.description}</Description>

                <Timestamp>
                    {moment(item.createdAt.toDate())
                        .format("Do MMMM, YYYY")
                        .toString()}
                </Timestamp>
            </Container>
            <hr style={{ opacity: 0.4, marginBottom: 30 }} />
        </>
    )
}

export default ResourceItem

const Container = styled(Flex)`
    width: 100%;
    flex-direction: column;
    cursor: pointer;
`
const Title = styled(Heading)`
    font-size: 20px;
    margin-bottom: 5px;
`
const Type = styled.p`
    margin-bottom: 15px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 3px;
`
const Description = styled.p`
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 150%;
`
const Timestamp = styled.p`
    opacity: 0.7;
    margin-bottom: 20px;
`
