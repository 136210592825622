import React from "react"
import { atom } from "recoil"

export const contentType = atom({
    key: "contentType",
    default: "",
})
export const colorScheme = atom({
    key: "colorScheme",
    default: "dark",
})
export const nameField = atom({
    key: "name",
    default: "",
})
export const emailField = atom({
    key: "email",
    default: "",
})
export const sidebarOpen = atom({
    key: "sidebarOpen",
    default: false,
})
export const globalUser = atom({
    key: "globalUser",
    default: null,
})
export const menuOpen = atom({
    key: "menuOpen",
    default: false,
})