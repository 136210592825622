import {
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { MainButton } from "../../styles/buttons"
import { Heading } from "../../styles/typography"
import PhealLoader from "../pheal-loader"

const ForgotPassword = ({ open, setOpen, firebase }) => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar()

    const handleClose = () => {
        setOpen(false)
    }

    const sendPasswordReset = () => {
        setLoading(true)
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(function () {
                // Email sent.
                enqueueSnackbar(
                    "We've sent a password reset link to your email.",
                    {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        autoHideDuration: 3000,
                    }
                )
                setEmail("")
                handleClose()
                setLoading(false)
            })
            .catch(function (error) {
                // An error happened.
                alert(error)
                setLoading(false)
            })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
        >
            <Heading style={{ padding: "15px 25px" }} id="form-dialog-title">
                What's your email?
            </Heading>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                {loading && <PhealLoader />}
                <MainButton
                    style={{ color: "black" }}
                    onClick={handleClose}
                    color="primary"
                >
                    Cancel
                </MainButton>
                <MainButton
                    dark
                    disabled={loading}
                    onClick={() => sendPasswordReset()}
                    color="primary"
                >
                    Reset
                </MainButton>
            </DialogActions>
        </Dialog>
    )
}
export default ForgotPassword
