import React from "react"
import styled from "styled-components"
import Navbar from "../../layout/navbar"
import { Flex, TextWrapper } from "../../layout/reusables"

const Container = styled(Flex)`
    height: 60vh;
    background: black;
`
const Logo = styled.img`
    width: 250px;
    height: 200px;
    margin-bottom: 50px;
    background: none;
    object-fit: contain;
`
const Content = styled(Flex)`
    padding: 20px;
`
const Name = styled.p`
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 5px;
    color: white;
    text-align: center;
    opacity: 0.7;
`
const Bio = styled.p`
    color: white;
    text-align: center;
    line-height: 140%;
    font-size: 18px;
`
// https://logos-download.com/wp-content/uploads/2016/03/Nike_logo_just_do_it.png"
const CollaboratorHeader = ({ name, bio, logo }) => {
    return (
        <Container align="center" justify="center">
            <Content align="center" justify="center" direction="column">
                <Logo
                    src={
                        logo
                            ? logo
                            : require("../../images/logo-placeholder.png")
                    }
                />
                <TextWrapper>
                    <Name>{name ? name : ""}</Name>
                    <Bio>{bio ? bio : ""}</Bio>
                </TextWrapper>
            </Content>
        </Container>
    )
}

export default CollaboratorHeader
