import React from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { BodyCopy } from "../../styles/typography"

export default function EmptyState() {
    return (
        <Container direction="column" align="center">
            <Image
                src={require("../../images/illustrations/conifer-235.png")}
            />
            <TextWrapper mBottom={20}>
                <BodyCopy style={{ textAlign: "center", opacity: 0.5 }}>
                    Lets create! — Choose a content type to get started.
                </BodyCopy>
            </TextWrapper>
        </Container>
    )
}

const Container = styled(Flex)`
    z-index: 0;
`
const Image = styled.img`
    max-width: 400px;
    object-fit: contain;
    margin-bottom: 30px;
`
