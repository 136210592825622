import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import styled from "styled-components"
import Layout from "../components/layout"
import PageHeading from "../components/page-heading"
import PrivateRoute from "../components/private-route"
import CollaboratorInformation from "../components/profile/collaborator-information"
import SEO from "../components/seo"
import { ContentWrapper, Flex, Toolbar, Wrapper } from "../layout/reusables"
import { FirebaseContext } from "../services"

const ProfilePage = () => {
    const { initialized, authToken } = useContext(FirebaseContext)

    useEffect(() => {
        if (initialized && !authToken) {
            navigate("/auth")
        }
    }, [initialized, authToken])

    return (
        <Layout dark>
            <SEO title="Your Profile" />
            <PrivateRoute path="/auth">
                <Wrapper>
                    {/* <Header /> */}
                    {initialized ? (
                        <ContentWrapper>
                            <PageHeading title="Build your Channel" />
                            <CollaboratorInformation />
                        </ContentWrapper>
                    ) : (
                        <Flex align="center" justify="center">
                            <Loader
                                type="Circles"
                                width={50}
                                height={50}
                                color="black"
                            />
                        </Flex>
                    )}
                </Wrapper>
            </PrivateRoute>
        </Layout>
    )
}

export default ProfilePage

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 20px;
    @media (max-width: 700px) {
        grid-template-columns: 100%;
    }
`
