import React from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, TextWrapper } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { BodyText, DigestiveText } from "../../styles/typography"

const PhealSeasons = () => {
    return (
        <ContentWrapper>
            <Flex
                style={{
                    position: "relative",
                    height: "100%",
                    color: colors.blue,
                    paddingTop: 100,
                    paddingBottom: 100,
                    scrollSnapAlign: "start",
                }}
                align="flex-end"
                justify="flex-start"
                direction="column"
            >
                <Title style={{ textAlign: "right" }} mBottom={30}>
                    Pheal Seasons
                </Title>
                <TextWrapper>
                    <BodyText style={{ textAlign: "right" }}>
                        Like the real world, PHEAL works in seasons. Our seasons
                        are three-month cycles that usher in new updates and
                        additions to the site. We work this way because it gives
                        us the time to fully reflect on feedback, develop new
                        ideas and introduce changes that genuinely improve the
                        PHEAL experience.
                    </BodyText>
                </TextWrapper>
            </Flex>
        </ContentWrapper>
    )
}

export default PhealSeasons

const Title = styled(DigestiveText)`
    font-size: 120px;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`
