import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, Wrapper } from "../layout/reusables"
import "./write.css"
import SEO from "../components/seo"

//EditorJS
import EditorJS from "@editorjs/editorjs"
import Header from "@editorjs/header"
import List from "@editorjs/list"
import Embed from "@editorjs/embed"
import Quote from "@editorjs/quote"

import { MainButton } from "../styles/buttons"
import { DigestiveText } from "../styles/typography"
import { FirebaseContext, originalContentRef } from "../state/FirebaseProvider"
import Layout from "../components/layout"
import PrivateRoute from "../components/private-route"
import { Backdrop, CircularProgress } from "@material-ui/core"

const WritePage = () => {
    const { user, _serverTimestamp } = useContext(FirebaseContext)

    const [editor, setEditor] = useState(null)
    const [storyId, setStoryId] = useState(originalContentRef.doc().id)
    const [saving, setSaving] = useState(false)
    const [title, setTitle] = useState("")

    useEffect(() => {
        // Add a new document with a generated id.
        const newStoryRef = originalContentRef.doc()

        setStoryId(newStoryRef.id)
    }, [])

    useEffect(() => {
        if (!editor && user) {
            const editorJS = new EditorJS({
                holder: "editorjs",
                // autofocus: true,
                tools: {
                    header: {
                        class: Header,
                    },
                    list: {
                        class: List,
                        inlineToolbar: true,
                    },
                    embed: {
                        class: Embed,
                    },
                    quote: {
                        class: Quote,
                    },
                },
                placeholder: "Let's write an awesome story...",
                onChange: api => {
                    handleQuickSave(editorJS)
                },
            })
            editorJS.isReady
                .then(() => {
                    console.log("Editor.js is ready to work! Sending to state")
                    setEditor(editorJS)
                    /** Do anything you need after editor initialization */
                })
                .catch(reason => {
                    console.log(
                        `Editor.js initialization failed because of ${reason}`
                    )
                })
        }
    }, [editor, user])

    const _formatOutputData = data => {
        console.log(user)
        console.log({ ...data, collaboratorId: user.uid })
        return {
            ...data,
            collaboratorId: user.uid,
            createdAt: _serverTimestamp(),
            lastUpdated: _serverTimestamp(),
            title: title,
            type: "article",
        }
    }

    const handleQuickSave = editor => {
        setSaving(true)
        editor
            .save()
            .then(async outputData => {
                console.log("Article data: ", outputData)
                console.log("Saving...")
                await originalContentRef
                    .doc(storyId)
                    .set(_formatOutputData(outputData), { merge: true })
                setSaving(false)
            })
            .catch(error => {
                console.log("Saving failed: ", error)
                setSaving(false)
            })
    }

    const handleSave = useCallback(() => {
        setSaving(true)
        editor
            .save()
            .then(async outputData => {
                console.log("Article data: ", outputData)
                console.log("Saving...")
                await originalContentRef
                    .doc(storyId)
                    .update(_formatOutputData(outputData))
                setSaving(false)
            })
            .catch(error => {
                console.log("Saving failed: ", error)
                setSaving(false)
            })
    }, [editor, user])

    return (
        <Layout dark>
            <SEO title="Write a new story" />
            <PrivateRoute path="/auth">
                <Wrapper>
                    {/* <Nav>
                        <DigestiveText fontSize={28}>PHEAL</DigestiveText>
                    </Nav> */}
                    <ContentWrapper style={{ marginTop: 50, padding: 30 }}>
                        <Holder>
                            <Title
                                maxLength={140}
                                rows="3"
                                placeholder="Title"
                                value={title}
                                name="title"
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => handleQuickSave(editor)}
                            />
                            <EditorContainer
                                style={{ background: "#fff" }}
                                id="editorjs"
                            />
                        </Holder>
                    </ContentWrapper>
                    <Toolbar>
                        <p>Draft {saving ? "Saving..." : "Saved."}</p>
                        <MainButton
                            disabled={saving}
                            dark
                            onClick={() => handleSave()}
                        >
                            {saving ? "Saving" : "Save"}
                        </MainButton>
                    </Toolbar>
                </Wrapper>
            </PrivateRoute>
        </Layout>
    )
}

export default WritePage

const Holder = styled(Flex)`
    height: 70vh;
    /* background-color: red; */
    flex-direction: column;
    font-family: "Haas";
    max-width: 650px;
    margin: 0 auto;
    align-items: center;
`
const EditorContainer = styled.div`
    width: 100%;
`

const Nav = styled.nav`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
`
const Title = styled.textarea`
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
    border: none;
    outline: none;
    font-family: "Haas";
    ::placeholder {
        color: #ccc;
    }
`
const Toolbar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid #eee;
`
