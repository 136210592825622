import React, { useState } from "react"
import useInView from "react-cool-inview"
import styled from "styled-components"
import { ContentWrapper, Flex, TextWrapper } from "../../layout/reusables"
import { BodyText, DigestiveText } from "../../styles/typography"

const Collaborators = () => {
    const [visible, setVisible] = useState(false)

    const { ref, inView } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.7,
        onEnter: () => {
            setVisible(true)
        },
    })

    return (
        <ContentWrapper ref={ref}>
            <Flex
                style={{
                    position: "relative",
                    height: "100%",
                    color: "white",
                    paddingTop: 100,
                    paddingBottom: 100,
                    scrollSnapAlign: "start",
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
            >
                <Title
                    initial={{ opacity: 0, y: "60%" }}
                    animate={{
                        opacity: inView ? 1 : 0,
                        y: inView ? 0 : "100%",
                    }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    mBottom={30}
                >
                    The Collaborators
                </Title>
                <TextWrapper>
                    <BodyText
                        initial={{ opacity: 0, y: "60%" }}
                        animate={{
                            opacity: inView ? 1 : 0,
                            y: inView ? 0 : "100%",
                        }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                    >
                        PHEAL collaborators all have their own specific niches.
                        Some may relate to social issues such as racism, gender
                        and sexuality and climate change; some may fall under
                        the broad spectrum of the sciences, technology, the arts
                        or business and entrepreneurship. Whatever your niche,
                        we want you to explore and dive as deeply as you desire
                        into the topics that matter to you.
                    </BodyText>
                </TextWrapper>
            </Flex>
        </ContentWrapper>
    )
}

export default Collaborators

const Title = styled(DigestiveText)`
    font-size: 120px;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`
