import React from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, TextWrapper } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { BodyText, DigestiveText } from "../../styles/typography"

const WePhealtThat = () => {
    return (
        <ContentWrapper>
            <Flex
                style={{
                    position: "relative",
                    height: "100%",
                    color: colors.blue,
                    paddingTop: 100,
                    paddingBottom: 100,
                    scrollSnapAlign: "start",
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
            >
                <Title mBottom={30}>We Phealt That</Title>
                <TextWrapper>
                    <BodyText>
                        Our featured collaborators section, known here as
                        WePhealtThat, is dedicated to collaborators who we want
                        to acknowledge for their amazing contributions to the
                        PHEAL community. With each new season, new collaborators
                        will be selected and featured to help increase their
                        visibility and promote the work that they’ve been doing.
                    </BodyText>
                </TextWrapper>
            </Flex>
        </ContentWrapper>
    )
}

export default WePhealtThat

const Title = styled(DigestiveText)`
    font-size: 120px;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`
