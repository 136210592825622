import React, { useContext, useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import withLocation from "../../components/withLocation";
import PrivateRoute from "../../components/private-route";
import { FirebaseContext, originalContentRef } from "../../state/FirebaseProvider";
import Layout from "../../components/layout";
import { ContentWrapper, Flex, Wrapper } from "../../layout/reusables";
import { Heading } from "../../styles/typography";
import { MainButton } from "../../styles/buttons";
import PhealLoader from "../../components/pheal-loader";
import { AnimatePresence } from "framer-motion";
import { Link, navigate } from "gatsby";
import { colors } from "../../styles/colors";
import ArticlePreviewItem from "../../components/create/article-preview-item";
import { useRecoilValue } from "recoil";
import { globalUser } from "../../state/atoms";
import { ARTICLE_TYPE, AUDIO_TYPE, PHOTO_TYPE, VIDEO_TYPE } from "../../components/create/content-types";
import PhotoPreviewItem from "../../components/create/photo-preview-item";
import VideoPreviewItem from "../../components/create/video-preview-item";
import AudioPreviewItem from "../../components/create/audio-preview-item";

const AllOriginalContent = ({ search }) => {

    const userId = useRecoilValue(globalUser)
    const { type = 'Article' } = search
    const query = originalContentRef
        .where("collaboratorId", "==", userId)
        .where("type", "==", type)
        .orderBy("createdAt", "desc");
        
    const [originalContent, loading, error] = useCollection(query);
    console.log(error)

	const originalContentData = useMemo(() => {
		if (originalContent) {
			return originalContent.docs.map((item) => ({
				id: item.id,
				...item.data(),
			}));
		}
		return [];
    }, originalContent);
    

	return (
        <PrivateRoute>
            <Layout dark>
                <Wrapper>
                    <ContentWrapper>
                        <Flex
                            justify="space-between"
                            align="center"
                            style={{ marginBottom: 30 }}
                        >
                            <Heading mBottom="0"> </Heading>
                            <MainButton dark onClick={() => navigate("/create")}>
                                Add New Content
                            </MainButton>
                        </Flex>
                        {loading && (
                            <PhealLoader />
                        )}
                        {originalContentData.length > 0 && (
                            <AnimatePresence>
                                <div key="articles-content">
                                    <Header>
                                        <Heading>{type}s</Heading>
                                    </Header>
                                    <Grid>
                                        {originalContentData &&
                                            originalContentData.map((item, index) => {
                                                switch (type) {
                                                    case ARTICLE_TYPE:
                                                        return <ArticlePreviewItem
                                                            key={index}
                                                            item={item}
                                                            index={index}
                                                            viewing
                                                        />
                                                    case PHOTO_TYPE:
                                                        return <PhotoPreviewItem
                                                            key={index}
                                                            item={item}
                                                            index={index}
                                                            viewing
                                                        />
                                                    case VIDEO_TYPE:
                                                        return <VideoPreviewItem
                                                            key={index}
                                                            item={item}
                                                            index={index}
                                                            viewing
                                                        />
                                                    case AUDIO_TYPE:
                                                        return <AudioPreviewItem
                                                            key={index}
                                                            item={item}
                                                            index={index}
                                                            viewing
                                                        />
                                                
                                                    default:
                                                        break;
                                                }

                                                
                                            })}
                                    </Grid>

                                </div>
                            </AnimatePresence>
                        )}
                    </ContentWrapper>
                </Wrapper>
            </Layout>
        </PrivateRoute>
    )
};

export default withLocation(AllOriginalContent);

const Container = styled.div``
const Grid = styled.div`
    display: grid;
    margin-bottom: 80px;
    grid-gap: 20px;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    
    @media (max-width: 550px) {
        grid-gap: 10px;
        grid-template-columns: repeat(1, 1fr);

    }
`
const ViewMore = styled(Link)`
    color: ${colors.blue};
    text-decoration: none;
    font-weight: 500;
`;
const Header = styled(Flex)`
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 40px;
`;