import React from "react"
import styled from "styled-components"

const LoginIllustration = () => {
    return (
        <Container>
            <Image
                src={require("../../images/illustrations/conifer-279.png")}
            />
        </Container>
    )
}

export default LoginIllustration

const Container = styled.div`
    z-index: 0;
    margin-right: 30px;
`
const Image = styled.img`
    max-width: 400px;
    object-fit: contain;

    @media (max-width: 850px) {
        max-width: 300px;
    }

    @media (max-width: 850px) {
        max-width: 200px;
    }
`
