import { motion } from "framer-motion"
import React, { useState } from "react"
import { MdClose } from "react-icons/md"
import { useLocalStorage } from "react-use"
import styled from "styled-components"
import { Flex, TextWrapper, Wrapper } from "../layout/reusables"
import { colors } from "../styles/colors"

const Container = styled(motion.div)`
    margin-bottom: 50px;

    @media (max-width: 550px) {
        margin-bottom: 30px;
    }
`
const Title = styled.h1`
    margin-bottom: ${props => (props.seenNote ? "20px" : "45px")};
    line-height: 1.4;
    font-size: 30px;

    @media (max-width: 550px) {
        margin-bottom: 35px;
        font-size: 32px;
    }
`
const Rule = styled.hr`
    background-color: #E6E6E6;
    opacity: 0.3;
`

const PageHeading = ({ title }) => {
    const [seenStillBuildingNote, setSeenStillBuildingNote] = useLocalStorage(
        "seenStillBuildingNote"
    )

    return (
        <Container positionTransition>
            <TextWrapper>
                <Title seenNote={!seenStillBuildingNote}>{title}</Title>
            </TextWrapper>
            {/* {!seenStillBuildingNote && (
                <StillBuildingNote toggle={setSeenStillBuildingNote} />
            )} */}
            <Rule />
        </Container>
    )
}

export default PageHeading

const StillBuildingNote = ({ toggle }) => {
    return (
        <Flex justify="space-between">
            <TextWrapper>
                <Copy>
                    <Bold>Hello Collaborator!</Bold> While our main site
                    continues to undergo its final updates, you’ve been granted
                    access to prepare all your resources for our official
                    launch. <br />
                    Once we go live, your channel and resources will be
                    available and we’ll have the honor of introducing you as one
                    of our first Featured Collaborators.
                </Copy>
            </TextWrapper>
            <Close
                onClick={() => {
                    toggle(true)
                }}
            >
                <MdClose size={30} />
            </Close>
        </Flex>
    )
}

const Copy = styled.p`
    color: ${colors.pink};
    font-size: 18px;
    line-height: 1.4;
`
const Bold = styled.span`
    font-weight: 500;
`

const Close = styled.div`
    cursor: pointer;
    height: 45px;
    width: 45px;
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    :hover {
        background: ${props => (props.black ? "#999" : "#fafafa")};
    }
`
