import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import ReadWatchItem from "./read-watch-item"
import { AddReadWatchItem } from "../../services/read-watch"
import { BodyCopy, Heading } from "../../styles/typography"
import Loader from "react-loader-spinner"
import { AnimatePresence } from "framer-motion"
import { FirebaseContext } from "../../services"
import { Dialog, DialogActions } from "@material-ui/core"
import Slide from "@material-ui/core/Slide"

const skeletonReadWatchItem = {
    url: "",
    title: "",
    description: "",
    type: "Read",
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const AddReadWatch = ({ open, setOpen }) => {
    const { user, initialized } = useContext(FirebaseContext)

    //State
    const [readWatchCollection, setReadWatchCollection] = useState([
        skeletonReadWatchItem,
    ])
    const [loading, setLoading] = useState(false)

    //Effects
    useEffect(() => {
        //If the last item in the collection is filled with a url, add another

        const lastItem = readWatchCollection[readWatchCollection.length - 1]
        if (lastItem.url) {
            setReadWatchCollection([
                ...readWatchCollection,
                skeletonReadWatchItem,
            ])
        }
    }, [readWatchCollection])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const updateItem = (index, item) => {
        const updatedReadWatchCollection = readWatchCollection
        updatedReadWatchCollection[index] = item
        setReadWatchCollection([...updatedReadWatchCollection])
    }

    const addReadWatch = async () => {
        setLoading(true)
        //Get valid items
        const readWatches = readWatchCollection.filter(el => el.url)
        const uploadTasks = readWatches.map(item =>
            AddReadWatchItem({ ...item, collaboratorId: user.uid })
        )

        await Promise.all(uploadTasks)
        setLoading(false)
        setReadWatchCollection([skeletonReadWatchItem])
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
        >
            <Container>
                <Heading mBottom={8}>Add Resources</Heading>
                <BodyCopy style={{ opacity: 0.8, marginBottom: 40 }}>
                    Paste links to the resources you wish to share. We'll
                    automatically pull in the title and a short description －
                    if available.
                </BodyCopy>
                <AnimatePresence>
                    {readWatchCollection.map((item, index) => (
                        <ReadWatchItem
                            key={index}
                            item={item}
                            index={index}
                            setReadWatchItem={updateItem}
                        />
                    ))}
                </AnimatePresence>
                <DialogActions>
                    <Flex align="center">
                        <MainButton
                            style={{ marginRight: 10 }}
                            onClick={() => addReadWatch()}
                            dark
                            disabled={
                                loading ||
                                readWatchCollection[0] === skeletonReadWatchItem
                            }
                        >
                            Add to your resources
                        </MainButton>
                        {loading && (
                            <Loader
                                type="Oval"
                                height={30}
                                width={30}
                                color="black"
                            />
                        )}
                    </Flex>
                </DialogActions>
            </Container>
        </Dialog>
    )
}

export default AddReadWatch

const Container = styled.div`
    padding: 30px 25px;
`
