import React from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { BodyCopy } from "../../styles/typography"

const EmptySidebar = ({ user, clickHandler }) => {
    return (
        <Container direction="column" align="center">
            <Image src={require("../../images/illustrations/taxi-2.png")} />
            <TextWrapper mBottom={20}>
                <BodyCopy style={{ textAlign: "center", opacity: 0.5 }}>
                    {!user
                        ? "Log in to access your resources. A home for all your favourite bits of knowledge."
                        : "Empty － for now. A home for all your favourite bits of knowledge."}
                </BodyCopy>
            </TextWrapper>
            <MainButton dark onClick={() => clickHandler()}>
                Add your first resource
            </MainButton>
        </Container>
    )
}

export default EmptySidebar

const Container = styled(Flex)`
    z-index: 0;
`
const Image = styled.img`
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 30px;
`
