import React from "react"
import styled from "styled-components"
import { Button } from "./reusables"
import { useRecoilValue } from "recoil"
import { contentType } from "../../state/atoms"

const ListItem = ({ resource }) => {
    return (
        <ItemContainer>
            <Name>{resource.node.name}</Name>
            <a target="_blank" href={resource.node.link}>
                <Button small>
                    {resource.node.type === "Video" ? "watch" : "read"}
                </Button>
            </a>
        </ItemContainer>
    )
}

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    color: #c30e61;
`
const Title = styled.h1`
    font-weight: 600;
    font-size: 22px;
    color: #c30e61;
    margin-bottom: 20px;
    /* font-family: "Montserrat"; */
`
const Name = styled.h3`
    font-size: 16px;
    font-weight: 600;
    /* font-family: "Montserrat"; */
    color: #c30e61;
    text-align: left;
    margin-right: 10px;
    margin-bottom: 10px;
`

const FullList = ({ resources }) => {
    const type = useRecoilValue(contentType)

    const randomFew = resources
        .filter(el => el.node.type === type)
        .sort(() => Math.random() - 0.5)
        .slice(0, 2)

    return (
        <Container>
            <Title>Some others your might like</Title>
            {randomFew.map((resource, index) => (
                <ListItem key={index} resource={resource} />
            ))}
        </Container>
    )
}

export default FullList

const Container = styled.div`
    margin: 30px 0;
`
