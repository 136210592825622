import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, Wrapper } from "../layout/reusables"
import "./write.css"
import SEO from "../components/seo"

import { MainButton } from "../styles/buttons"
import { FirebaseContext, originalContentRef } from "../state/FirebaseProvider"
import Layout from "../components/layout"
import PrivateRoute from "../components/private-route"
import withLocation from "../components/withLocation"
import Loader from "react-loader-spinner"
import { ARTICLE_TYPE, PHOTO_TYPE, VIDEO_TYPE, AUDIO_TYPE } from "../components/create/content-types"
import { useSnackbar } from "notistack"
import ArticleContent from "../components/create/article-content"
import PhotoContent from "../components/create/photo-content"
import VideoContent from "../components/create/video-content"
import AudioContent from "../components/create/audio-content"
import PhealLoader from "../components/pheal-loader"

const EditPage = ({ search, location }) => {
    const { user, _serverTimestamp, firebase } = useContext(FirebaseContext)

    const [loading, setLoading] = useState(true)
    const [contentType, setContentType] = useState('')
    const [ originalContentData, setOriginalContentData ] = useState(null)


    const { id: contentDocumentId } = search

    useEffect(() => {
        loadContent()
    }, [contentDocumentId])


    const loadContent = async () => {
        const contentDoc = await originalContentRef.doc(contentDocumentId).get()
        const contentData = contentDoc.data()
        setOriginalContentData({id: contentDoc.id, ...contentData})
        setContentType(contentData?.type)
        setLoading(false)
    }


    if (loading) {
        return (
            <Layout dark>
                <PrivateRoute path="/auth">
                    <Wrapper>
                        <ContentWrapper>
                            <Flex style={{height: '30vh'}} align="center" justify="center">
                                <PhealLoader />
                            </Flex>
                        </ContentWrapper>
                    </Wrapper>
                </PrivateRoute>
            </Layout>
        )
    }

    return (
        <Layout dark>
            <SEO title={`Edit your ${contentType}`} />
            <PrivateRoute path="/auth">
                <Wrapper>
                    <ContentWrapper style={{ marginTop: 50, padding: 30 }}>
                        {contentType === ARTICLE_TYPE &&
                            <ArticleContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                                data={originalContentData}
                            />
                        }
                        {contentType === PHOTO_TYPE &&
                            <PhotoContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                                data={originalContentData}
                            />
                        }
                        {contentType === VIDEO_TYPE && 
                            <VideoContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                                data={originalContentData}
                            />
                        }
                        {contentType === AUDIO_TYPE && 
                            <AudioContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                                data={originalContentData}
                            />
                        }
                    </ContentWrapper>
                </Wrapper>
            </PrivateRoute>
        </Layout>
    )
}

export default withLocation(EditPage)