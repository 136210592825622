import { motion } from "framer-motion"
import { Link, navigate } from "gatsby"
import React, { useContext, useEffect } from "react"
import Div100vh from "react-div-100vh"
import styled from "styled-components"
import { MdClose } from "react-icons/md"
import { DigestiveText } from "../styles/typography"
import { useRecoilState, useSetRecoilState } from "recoil"
import { sidebarOpen } from "../state/atoms"
import { MainButton } from "../styles/buttons"
import { Flex } from "../layout/reusables"
import ResourceList from "./resource-list"
import { colors } from "../styles/colors"
import { FirebaseContext } from "../services"

const SideBar = () => {
    const { user, firebase } = useContext(FirebaseContext)
    const [isSidebarOpen, toggleSideBar] = useRecoilState(sidebarOpen)

    const html = document.querySelector("html")

    useEffect(() => {
        isSidebarOpen
            ? (html.style.overflow = "hidden")
            : (html.style.overflow = "visible")
    }, [isSidebarOpen])

    const handleLogOut = () => {
        firebase
            .auth()
            .signOut()
            .then(function () {
                navigate("/auth")
                toggleSideBar(false)
                // Sign-out successful.
            })
            .catch(function (error) {
                console.log(error.message)
                // An error happened.
            })
    }

    return (
        <Wrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <InnerContainer
                as={motion.div}
                initial={{ x: "100%" }}
                exit={{ x: "100%" }}
                transition={{ type: "tween", stiffness: 60 }}
                animate={{ x: 0 }}
                lockBody={isSidebarOpen}
            >
                <Header>
                    <MainButton
                        onClick={() => {
                            navigate("/")
                            toggleSideBar(false)
                        }}
                        dark
                    >
                        Add resources
                    </MainButton>
                    <Close
                        onClick={() => {
                            toggleSideBar(false)
                        }}
                    >
                        <MdClose size={30} />
                    </Close>
                </Header>
                <Nav>
                    <NavItem to="/profile" onClick={() => toggleSideBar(false)}>
                        Your Profile
                    </NavItem>
                    <NavItem to="/" onClick={() => toggleSideBar(false)}>
                        Read/Watch Collection
                    </NavItem>
                    <NavItem to="/create" onClick={() => toggleSideBar(false)}>
                        Original Content
                    </NavItem>
                </Nav>
                <hr style={{ opacity: 0.3 }} />
                <ResourceList />
            </InnerContainer>
            {user && (
                <FooterContent
                    initial={{ x: "100%" }}
                    exit={{ x: "100%" }}
                    transition={{ type: "tween", stiffness: 60 }}
                    animate={{ x: 0 }}
                >
                    <MainButton onClick={() => handleLogOut()}>
                        Logout
                    </MainButton>
                </FooterContent>
            )}
        </Wrapper>
    )
}

export default SideBar

const Wrapper = styled(motion.div)`
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    /* backdrop-filter: blur(2px); */
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled(Div100vh)`
    background: black;
    background: ${props => (props.black ? "#101010" : "white")};
    position: absolute;
    height: 100%;
    z-index: 101;
    top: 0;
    right: 0;
    padding: 30px 25px;
    padding-bottom: 160px;
    width: 100%;
    max-width: 450px;
    border-left: 1px solid #ececec;
    border-left: ${props =>
        props.black ? "1px solid #444444" : "1px solid #ECECEC"};
    box-shadow: -4px 0 14px rgba(0, 0, 0, 0.05);
    overflow: ${props => (props.lockBody ? "scroll" : "hidden")};
    color: ${props => (props.black ? "white" : "black")};

    @media (max-width: 550px) {
        padding: 60px 20px;
        padding-bottom: 180px;
        max-width: none;
        width: auto;
        width: -webkit-fill-available;
    }
`
const Header = styled(Flex)`
    justify-content: space-between;
    margin-bottom: 50px;
`
const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: scroll;
    /* height: 100%; */
    justify-content: flex-start;
    margin-bottom: 25px;
    /* position: sticky; */
    /* top: 0; */

    //Hide scroll bars
    //Firefox
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
`
const NavItem = styled(Link)`
    font-size: 13px;
    color: ${colors.blue};
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
    text-align: left;
    margin-right: 15px;

    :hover {
        text-decoration: underline;
    }
`
const Close = styled.div`
    cursor: pointer;
    height: 45px;
    width: 45px;
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    :hover {
        background: ${props => (props.black ? "#999" : "#fafafa")};
    }
`

const FooterContent = styled(motion.div)`
    position: fixed;
    width: 100%;
    z-index: 101;
    background: ${props => (props.black ? "#101010" : "white")};
    /* left: 0; */
    max-width: 450px;
    right: 0;
    padding: 5px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #eee;
    align-items: center;
    bottom: 0;
    @media (max-width: 550px) {
        bottom: 20px;
        padding: 10px 20px;
    }
`
