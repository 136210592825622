import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { useCollection } from "react-firebase-hooks/firestore"
import Loader from "react-loader-spinner"
import { AnimatePresence } from "framer-motion"
import { Heading } from "../../styles/typography"
import { Flex } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import EmptyCollection from "../empty-states/empty-collection"
import { originalContentRef } from "../../state/FirebaseProvider"
import { Link, navigate } from "gatsby"
import ArticlePreviewItem from "../create/article-preview-item"
import { ARTICLE_TYPE, PHOTO_TYPE, VIDEO_TYPE, AUDIO_TYPE } from "../create/content-types"
import PhotoPreviewItem from "../create/photo-preview-item"
import VideoPreviewItem from "../create/video-preview-item"
import AudioPreviewItem from "../create/audio-preview-item"
import { colors } from "../../styles/colors"

const ViewOriginalContent = ({ userId }) => {

    //TODO: Call for different content types individually, so we can limit to the first 3, instead of faking it.

    const query = originalContentRef
        .where("collaboratorId", "==", userId)
        .orderBy("createdAt", "desc")
    const [originalContent, loading, error] = useCollection(query)

    const originalContentData = useMemo(() => {
        if (originalContent) {
            return originalContent.docs.map(item => ({
                id: item.id,
                ...item.data(),
            }))
        }
        return []
    }, [originalContent])

    const articles = originalContentData.filter(el => el.type === ARTICLE_TYPE)
    const photos = originalContentData.filter(el => el.type === PHOTO_TYPE)
    const videos = originalContentData.filter(el => el.type === VIDEO_TYPE)
    const audios = originalContentData.filter(el => el.type === AUDIO_TYPE)

    return (
        <Container>
            <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 30 }}
            >
                <Heading mBottom="0"> </Heading>
                <MainButton dark onClick={() => navigate("/create")}>
                    Add New Content
                </MainButton>
            </Flex>
            {loading && (
                <Loader type="Oval" height={30} width={30} color="black" />
            )}
            {articles.length > 0 && (
                <AnimatePresence>
                    <div key="articles-content">
                        <Header>
                            <Heading>Articles</Heading>
                            {articles.length > 3 && <ViewMore to="/original-content/all?type=Article">View all articles</ViewMore>}
                        </Header>
                        <Grid>
                            {articles &&
                                articles.slice(0, 3).map((item, index) => (
                                    <ArticlePreviewItem
                                        key={index}
                                        item={item}
                                        index={index}
                                        viewing
                                    />
                                ))}
                        </Grid>
                        {originalContent && originalContent.empty && (
                            <EmptyCollection
                                clickHandler={() => navigate("/create")}
                            />
                        )}

                    </div>
                </AnimatePresence>
            )}
            {photos.length > 0 && (
                <AnimatePresence>
                    <div key="photos-content">
                        <Header>
                            <Heading>Photos</Heading>
                            {photos.length > 3 && <ViewMore to="/original-content/all?type=Photo">View all photos</ViewMore>}
                        </Header>
                        <Grid>
                            {photos &&
                                photos.slice(0, 3).map((item, index) => (
                                    <PhotoPreviewItem
                                        key={index}
                                        item={item}
                                        index={index}
                                        viewing
                                    />
                                ))}
                        </Grid>

                    </div>
                </AnimatePresence>
            )}
            {videos.length > 0 && (
                <AnimatePresence>
                    <div key="videos-content">
                        <Header>
                            <Heading>Videos</Heading>
                            {videos.length > 3 && <ViewMore to="/original-content/all?type=Video">View all videos</ViewMore>}
                        </Header>
                        <Grid>
                            {videos &&
                                videos.slice(0, 3).map((item, index) => (
                                    <VideoPreviewItem
                                        key={index}
                                        item={item}
                                        index={index}
                                        viewing
                                    />
                                ))}
                        </Grid>

                    </div>
                </AnimatePresence>
            )}
            {audios.length > 0 && (
                <AnimatePresence>
                    <div key="audios-content">
                        <Header>
                            <Heading>Audio</Heading>
                            {audios.slice(0, 3).length > 3 && <ViewMore to="/original-content/all?type=Audio">View all audio</ViewMore>}
                        </Header>
                        <Grid>
                            {audios &&
                                audios.map((item, index) => (
                                    <AudioPreviewItem
                                        key={index}
                                        item={item}
                                        index={index}
                                        viewing
                                    />
                                ))}
                        </Grid>

                    </div>

                </AnimatePresence>
            )}
        </Container>
    )
}

export default ViewOriginalContent

const Container = styled.div``
const Grid = styled.div`
    display: grid;
    margin-bottom: 80px;
    grid-gap: 20px;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    
    @media (max-width: 550px) {
        grid-gap: 10px;
        grid-template-columns: repeat(1, 1fr);

    }

`
const ViewMore = styled(Link)`
    color: ${colors.blue};
    text-decoration: none;
    font-weight: 500;
`;
const Header = styled(Flex)`
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 40px;
`;