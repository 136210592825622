import { Dialog } from "@material-ui/core"
import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../layout/reusables"
import { MainButton } from "../styles/buttons"
import { DigestiveText } from "../styles/typography"

const HouseRules = ({ open, setOpen, data }) => {
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Dialog
            open={open}
            keepMounted
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
        >
            <Container>
                <TextWrapper>
                    <Flex direction="column" align="center">
                        <Logo />
                        <DigestiveText
                            mBottom={30}
                            style={{ textAlign: "center" }}
                        >
                            {data.title}
                        </DigestiveText>
                        <Content
                            dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                        <MainButton onClick={() => navigate("/profile")} dark>
                            Got it!
                        </MainButton>
                    </Flex>
                </TextWrapper>
            </Container>
        </Dialog>
    )
}
export default HouseRules

const Container = styled.div`
    padding: 40px 25px;
`
const Logo = styled.img``
const Title = styled.h2``
const Content = styled.div`
    font-family: "Haas";

    h5 {
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        font-size: 16px;
        margin-bottom: 25px;
        line-height: 1.4;
        opacity: 0.9;
    }

    ul {
        margin-bottom: 20px;
        margin-left: 15px;
    }

    li {
        opacity: 0.9;
        font-size: 16px;
        list-style: circle;
        line-height: 1.4;
    }
`
