/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import { GlobalStyles } from "../styles/global-style"
import { FirebaseProvider } from "../services/index"
import SideBar from "./sidebar"
import { useRecoilValue } from "recoil"
import { menuOpen, sidebarOpen } from "../state/atoms"
import { AnimatePresence } from "framer-motion"
import Navbar from "../layout/navbar"
import { useMedia } from "react-use"
import MobileNavbar from "../layout/mobile-navbar"
import Menu from "../layout/menu"
if (typeof window !== "undefined") {
    require("pace-progressbar")
    require("pace-progressbar/themes/black/pace-theme-minimal.css")
}

const Layout = ({ children, dark, noBlur, fixed }) => {
    const sideBarOpened = useRecoilValue(sidebarOpen)
    const isMenuOpen = useRecoilValue(menuOpen)
    const isWide = useMedia("(min-width: 500px)")
    const html =
        typeof window !== "undefined" ? document.querySelector("html") : {}

    useEffect(() => {
        sideBarOpened
            ? (html.style.overflow = "hidden")
            : (html.style.overflow = "visible")
    }, [sideBarOpened])

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <FirebaseProvider>
            <GlobalStyles />
            <AnimatePresence>{sideBarOpened && <SideBar />}</AnimatePresence>
            {isWide ? (
                <Navbar noBlur={noBlur} dark={dark} fixed={fixed} />
            ) : (
                <>
                    <MobileNavbar dark={dark}/>
                    <AnimatePresence>{isMenuOpen && <Menu />}</AnimatePresence>
                </>
            )}
            
            <Main>{children}</Main>
        </FirebaseProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

const Main = styled.main`
    padding: 30px 0;
`