import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "./reusables"
import HamburgerMenu from "react-hamburger-menu"
import { useRecoilState } from "recoil"
import { sidebarOpen } from "../state/atoms"
import { colors } from "../styles/colors"

const Container = styled.nav`
    padding: 15px 20px;
    /* background: ${props =>
        props.noBlur ? "transparent" : "rgba(255, 255, 255, 0.7)"};
    backdrop-filter: blur(25px); */

    background: white;
    position: ${props => (props.fixed ? "fixed" : "sticky")};
    left: 0;
    right: 0;
    z-index: 99;
    top: 0;
    color: ${props => (props.dark ? "black" : "white")} !important;
`
const Logo = styled.img`
    max-width: 120px;
    flex: 1;
`
const Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
`
const ActionNav = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`
const NavItem = styled(Link)`
    font-size: 13px;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
    text-align: left;
    margin-right: 15px;

    :hover {
        text-decoration: underline;
    }
`
const Burger = styled.div`
    cursor: pointer;
`

const Navbar = ({ noBlur, dark, fixed }) => {
    const [sideBar, setSideBar] = useRecoilState(sidebarOpen)

    return (
        <Container noBlur={noBlur} dark={dark} fixed={fixed}>
            <Nav>
                <Link to="/">
                    <Logo
                        src={require(`../images/Pheal-Logo-${
                            dark ? "Black" : "White"
                        }.png`)}
                    />
                </Link>
                <ActionNav>
                    <NavItem to="/" activeStyle={{textDecoration: 'underline'}}>Read/Watch Collection</NavItem>
                    <NavItem to="/original-content" activeStyle={{textDecoration: 'underline'}}>Original Content</NavItem>
                    <NavItem to="/profile" activeStyle={{textDecoration: 'underline'}}>Your Profile</NavItem>
                </ActionNav>
                {/* <Burger>
                    <HamburgerMenu
                        isOpen={sideBar}
                        menuClicked={() => setSideBar(!sideBar)}
                        width={23}
                        height={15}
                        strokeWidth={2}
                        rotate={0}
                        color={!dark ? "white" : "black"}
                        borderRadius={0}
                        animationDuration={0.5}
                    />
                </Burger> */}
            </Nav>
        </Container>
    )
}

export default Navbar
