import React from "react"
import { RecoilRoot } from "recoil"
import { FirebaseProvider } from "../services"
import { SnackbarProvider } from "notistack"

export default ({ element }) => (
    <RecoilRoot>
        <SnackbarProvider maxSnack={3}>
            <FirebaseProvider>{element}</FirebaseProvider>
        </SnackbarProvider>
    </RecoilRoot>
)
