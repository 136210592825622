import { motion } from "framer-motion"
import Div100vh from "react-div-100vh"
import styled from "styled-components"

export const SectionContainer = styled(motion.section)`
    min-height: 100vh;
    position: relative;
    min-height: 100vh;
    padding: 0 20px;
    background-color: ${props => props.background};
`
