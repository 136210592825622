import React from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { BodyCopy } from "../../styles/typography"

const EmptyCollection = ({ clickHandler }) => {
    return (
        <Flex direction="column" align="center" style={{marginBottom: 30}}>
            <TextWrapper>
                <Flex direction="column" align="center">
                    <Image src={require("../../images/empty-resources.svg")} />
                    <BodyCopy
                        style={{
                            marginBottom: 15,
                            textAlign: "center",
                            color: "#515151",
                        }}
                    >
                        Feels a little empty! — Add your first resource to get
                        started. A resource is a URL that links to anything to
                        you wish to share, be it a blog post, a recipe, Youtube
                        video or a special podcast.
                    </BodyCopy>
                    <MainButton dark onClick={() => clickHandler()}>
                        Add New Resources
                    </MainButton>
                </Flex>
            </TextWrapper>
        </Flex>
    )
}
export default EmptyCollection

const Image = styled.img`
    max-height: 300px;
`
