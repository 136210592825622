import { motion } from "framer-motion"
import styled from "styled-components"

export const Flex = styled(motion.div)`
  display: flex;
  
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${({ justify }) => justify && `justify-content: ${justify};`}
`
export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`
export const ContentWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 20px;
    position: relative;

    @media (max-width: 550px) {
        padding: 50px 20px;
    }
`
export const TextWrapper = styled.div`
    max-width: 55ch;
    margin-bottom: ${props => props.mBottom ?? 0}px;
`
export const Toolbar = styled.div`
    position: fixed;
    background-color: white;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    justify-content: space-between;
    padding: 20px 40px;
    border-top: 1px solid #eee;
    z-index: 2;

    @media (max-width: 550px) {
        padding: 10px 20px;

    }
`
