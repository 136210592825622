import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import axios from "axios"
import { BodyCopy, Heading } from "../../styles/typography"
import DeleteDialog from "./delete-dialog"
import Skeleton from "react-loading-skeleton"

const ReadWatchItem = ({ item, index, setReadWatchItem, viewing }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const getTitle = async () => {
            //If a url exists, try to fetch the title
            if (item.url && !viewing) {
                const res = await axios.post(
                    "/.netlify/functions/get-url-title",
                    JSON.stringify({
                        url: item.url,
                    })
                )

                if (res.data?.result?.meta?.title) {
                    const { title, description } = res.data.result.meta
                    setReadWatchItem(index, {
                        ...item,
                        title: title ?? "",
                        description: description ?? "",
                    })
                }
            }
        }
        getTitle()
    }, [item.url, viewing])

    const handleUpdateDescription = e => {
        const value = e.target.value
        setReadWatchItem(index, {
            ...item,
            description: value,
        })
    }

    return (
        <RWItem
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            positionTransition
        >
            {!viewing && (
                <>
                    <Flex>
                        <Input
                            placeholder={`Paste a link ${
                                index === 0 ? "to get started" : ""
                            }`}
                            onChange={e =>
                                setReadWatchItem(index, {
                                    ...item,
                                    url: e.target.value,
                                })
                            }
                            disabled={viewing}
                            value={item.url}
                        />

                        <Select
                            value={item.type}
                            disabled={viewing}
                            onChange={e =>
                                setReadWatchItem(index, {
                                    ...item,
                                    type: e.target.value,
                                })
                            }
                        >
                            <option>Read</option>
                            <option>Watch</option>
                        </Select>
                    </Flex>
                </>
            )}
            <Meta>
                {viewing ? (
                    <a style={{ color: "inherit" }} href={item.url}>
                        <Title>{item.title}</Title>
                    </a>
                ) : item.url ? (
                    <Title>{item.title || <Skeleton count={2} />}</Title>
                ) : (
                    []
                )}
                {item.title && (
                    <Description
                        placeholder="Write a description..."
                        defaultValue={item.description}
                        value={item.description}
                        rows={7}
                        onInput={handleUpdateDescription}
                    />
                )}
                {viewing && (
                    <Remove onClick={() => setOpen(true)}>Remove</Remove>
                )}
            </Meta>
            <hr style={{ opacity: 0.3 }} />
            <DeleteDialog setOpen={setOpen} open={open} id={item.id} />
        </RWItem>
    )
}

export default ReadWatchItem

const RWItem = styled(motion.div)`
    margin-bottom: 35px;
`
const Input = styled.input`
    background-color: #f3f3f3;
    border-radius: 3px;
    font-size: 16px;
    padding: 15px 20px;
    width: 100%;
    max-width: 400px;
    margin-right: 15px;
    font-family: "Haas";
    border: none;
    outline: none;
`
const Select = styled.select`
    /* width: 100%; */
    border: 1px solid #e7e7e7;
    background-color: #f3f3f3;
    padding: 10px;
    /* height: 56px; */
    font-family: "Haas";
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Meta = styled.div`
    padding: 5px 10px;
    margin-bottom: 15px;
`

const Title = styled(Heading)`
    font-size: 17px;
    margin-bottom: 6px;
`
const Description = styled.textarea`
    font-size: 16px;
    opacity: 0.8;
    border: none;
    border-bottom: 1.5px solid #dedede;
    /* background-color: #f3f3f3; */
    border-radius: 3px;
    font-size: 16px;
    padding: 15px;
    width: 100%;
    max-width: 400px;
    margin-right: 15px;
    font-family: "Haas";
    outline: none;
`
const Remove = styled.button`
    background: none;
    outline: none;
    color: red;
    border: none;
    margin-top: 15px;
    cursor: pointer;
`
