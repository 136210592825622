import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "../components/blackout/reusables"
import { Link } from "gatsby"
import { useSetRecoilState } from "recoil"
import { contentType } from "../state/atoms"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import Div100vh from "react-div-100vh"
import { colors } from "../styles/colors"
import PrivateRoute from "../components/private-route"

const IndexPage = ({ data }) => {
    const setType = useSetRecoilState(contentType)

    useEffect(() => {
        setType("")
    }, [])

    return (
        <Layout>
            <PrivateRoute path="/auth">
                <Container style={{ background: "black" }}>
                    <SEO title="PHEAL" />
                    <CoverContainer>
                        <Img
                            fluid={data.file.childImageSharp.fluid}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </CoverContainer>
                    <Title>
                        Coming Soon •{" "}
                        <Link
                            style={{
                                color: colors.orange,
                                textDecoration: "none",
                            }}
                            to="/how-it-works"
                        >
                            See how it works
                        </Link>
                    </Title>
                </Container>
            </PrivateRoute>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        file(relativePath: { eq: "cover.png" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

const CoverContainer = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
    margin: 0;
`

const Container = styled(Div100vh)`
    background: black;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    padding: 20px;
`
const Title = styled.p`
    font-size: 17px;
    letter-spacing: 2px;
    text-align: center;
    line-height: 1.5;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    z-index: 2;
    margin-bottom: 40px;
`
