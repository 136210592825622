import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import { useSetRecoilState } from "recoil"
import { globalUser } from "./atoms"

// The type definition for the firebase context data.

// The firebase context that will store the firebase instance and other useful variables.
export const FirebaseContext = React.createContext({
    authToken: null,
    firebase,
    setAuthToken: () => {},
    user: null,
    userData: null,
    initialized: false,
})

const db = firebase.firestore()

export const collaboratorAccessRef = db.collection("approved-collaborators")
export const collaboratorsRef = db.collection("collaborators")
export const readwatchRef = db.collection("read-watch")
export const originalContentRef = db.collection("original-content")

// The provider that will store the logic for manipulating the firebase instance and variables.
export const FirebaseProvider = ({ children }) => {
    const [authToken, setAuthToken] = React.useState(null)
    const [user, setUser] = useState(null)
    const [userData, setUserData] = useState(null)
    const [initialized, setInitialized] = useState(false)

    //Recoil State
    const setGlobalUser = useSetRecoilState(globalUser)

    // A method for setting the authToken in state and local storage.
    const onSetAuthToken = token => {
        setAuthToken(token)
        localStorage.setItem("authToken", token)
    }

    const _serverTimestamp = () => {
        return firebase.firestore.FieldValue.serverTimestamp()
    }

    // If we have the window object and there is no authToken then try to get the authToken from local storage.
    React.useEffect(() => {
        if (typeof window !== "undefined" && !authToken) {
            const token = window.localStorage.getItem("authToken")
            if (token) {
                onSetAuthToken(token)
            }
        }
    }, [authToken])

    //Get latest user information
    React.useEffect(() => {
        const getUserData = async () => {
            const collaborator = await collaboratorsRef.doc(user.uid).get()
            setUserData({ id: user.uid, ...collaborator.data() })
        }

        if (user && initialized) {
            getUserData()
        }
    }, [user, initialized])

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setAuthToken(user.refreshToken)
                setUser(user)
                setGlobalUser(user.uid)
                setInitialized(true)
            } else {
                // No user is signed in.
                console.log("no user signed in")
                setInitialized(true)
            }
        })
    }, [setGlobalUser])

    return (
        <FirebaseContext.Provider
            value={{
                authToken,
                firebase,
                setAuthToken: onSetAuthToken,
                user,
                userData,
                initialized: initialized,
                _serverTimestamp: _serverTimestamp,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    )
}
