import React, { useState } from "react"
import styled from "styled-components"
import { useCollection } from "react-firebase-hooks/firestore"
import firebase from "gatsby-plugin-firebase"
import Loader from "react-loader-spinner"
import ReadWatchItem from "./read-watch-item"
import { AnimatePresence } from "framer-motion"
import { Heading } from "../../styles/typography"
import DisplayReadWatchItem from "./display-read-watch-item"
import { Flex } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import AddReadWatch from "./add-read-watch"
import EmptyCollection from "../empty-states/empty-collection"
import ReadWatchPreview from "./read-watch-preview"

const ViewReadWatch = ({ userId }) => {
    const query = firebase
        .firestore()
        .collection("read-watch")
        .where("collaboratorId", "==", userId)
        .orderBy("createdAt", "asc")
    const [readWatches, loading, error] = useCollection(query)

    const [addModalOpen, setAddModalOpen] = useState(false)

    return (
        <Container>
            <Flex
                justify="space-between"
                align="center"
                style={{ marginBottom: 30 }}
            >
                <Heading mBottom="0">Your Collection</Heading>
                <MainButton dark onClick={() => setAddModalOpen(true)}>
                    Add New Resources
                </MainButton>
            </Flex>
            {loading && (
                <Loader type="Oval" height={30} width={30} color="black" />
            )}

            <AnimatePresence>
                <Grid>
                    {readWatches &&
                        readWatches.docs.map((item, index) => (
                            <DisplayReadWatchItem
                                key={index}
                                item={{ id: item.id, ...item.data() }}
                                index={index}
                                viewing
                                // setReadWatchItem={updateItem}
                            />
                        ))}
                </Grid>
                {readWatches && readWatches.empty && (
                    <EmptyCollection
                        clickHandler={() => setAddModalOpen(true)}
                    />
                )}
            </AnimatePresence>
            {readWatches && (
                <ReadWatchPreview
                    collection={readWatches.docs.map(el => el.data())}
                />
            )}
            <AddReadWatch open={addModalOpen} setOpen={setAddModalOpen} />
        </Container>
    )
}

export default ViewReadWatch

const Container = styled.div``
const Grid = styled.div`
    display: grid;
    margin-bottom: 50px;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @media (max-width: 550px) {
        grid-gap: 10px;
    }
`
