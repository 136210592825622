import React from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { BodyText } from "../../styles/typography"

const AddonItem = ({ name, price, features }) => {
    return (
        <Container direction="row" align="center" justify="space-between">
            <Name>{name}</Name>
            <Price>+${price}</Price>
        </Container>
    )
}

export default AddonItem

const Container = styled(Flex)`
    background: rgba(210, 211, 255, 0.68);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 20px;
`
const Name = styled(BodyText)`
    color: #000373;
    font-size: 16px;
`
const Price = styled(BodyText)`
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
`
const Note = styled(BodyText)`
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
    color: black;
    opacity: 0.6;
`
const FeatureList = styled.ul`
    margin-bottom: 30px;
`
const FeatureItem = styled.li`
    text-align: center;
    margin-bottom: 10px;
    color: ${colors.blue};
`
