import React, { useCallback } from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { BodyText, DigestiveText } from "../../styles/typography"
import { SZN_PRICING, ANNUAL_PRICING } from "./membership-and-pricing"
const PricingItem = ({
    name,
    features,
    sznPrice,
    annualPrice,
    soon,
    selectedCycle,
}) => {
    const _renderPrice = useCallback(() => {
        switch (selectedCycle) {
            case SZN_PRICING:
                return sznPrice
            case ANNUAL_PRICING:
                return annualPrice
            default:
                return "0"
        }
    }, [selectedCycle, sznPrice, annualPrice])

    return (
        <Container
            cycle={selectedCycle}
            direction="column"
            align="center"
            justify="center"
        >
            <Name>{name}</Name>
            <Price>${_renderPrice()}</Price>
            {sznPrice !== "0" && annualPrice !== "0" && (
                <Note>
                    per {selectedCycle === SZN_PRICING ? "season" : "year"},
                    billed every three months
                </Note>
            )}
            {features && (
                <FeatureList>
                    {features.map((feature, i) => (
                        <FeatureItem key={i}>{feature}</FeatureItem>
                    ))}
                </FeatureList>
            )}
            {soon && <Soon>Coming Soon</Soon>}
            {/* <MainButton>Coming Soon</MainButton> */}
        </Container>
    )
}

export default PricingItem

const Container = styled(Flex)`
    background: rgba(0, 0, 0, 0.12);
    /* backdrop-filter: blur(15px); */
    border-radius: 8px;
    border: 3px solid #0005f5;
    /* width: 100%; */
    margin-bottom: 30px;
    max-width: 400px;
    padding: 20px;
    transition: 0.3s ease;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
    /* 
    :hover {
        background: rgba(210, 211, 255, 0.9);
    } */
`
const Name = styled(BodyText)`
    color: white;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
`
const Price = styled(BodyText)`
    color: ${colors.yellow};
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
`
const Note = styled(BodyText)`
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 500;
    color: white;
    opacity: 0.6;
`
const FeatureList = styled.ul`
    /* margin-bottom: 30px; */
    color: white;
    padding: 0 10px;
`
const FeatureItem = styled.li`
    text-align: center;
    font-weight: 400;
    margin-bottom: 12px;
    /* color: ${colors.blue}; */
`
const Soon = styled(DigestiveText)`
    text-transform: none;
    margin-top: 15px;
    opacity: 0.7;
    font-size: 40px;
`
