import React from "react"
import styled from "styled-components"
import { Flex, Wrapper } from "../../layout/reusables"
import { BodyText, DigestiveText } from "../../styles/typography"
import { BsChevronCompactDown } from "react-icons/bs"
import { use100vh } from "react-div-100vh"
import { motion } from "framer-motion"

const Welcome = () => {
    const height = use100vh()

    return (
        <Flex
            as={MotionDiv}
            style={{
                position: "relative",
                height,
                scrollSnapAlign: "center",
            }}
            align="center"
            justify="center"
            direction="column"
        >
            <Title
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 2, ease: "easeInOut" }}
            >
                Welcome
            </Title>
            <Flex
                style={{ position: "absolute", bottom: 50 }}
                align="center"
                justify="center"
                direction="column"
            >
                <BodyText fontSize={20} style={{ marginBottom: 10 }}>
                    So, how does all of this work?
                </BodyText>
                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: "70%" }}
                    transition={{
                        loop: Infinity,
                        yoyo: true,
                        duration: 2,
                        repeatDelay: 1.4,
                    }}
                >
                    <BsChevronCompactDown size={30} />
                </motion.div>
            </Flex>
        </Flex>
    )
}

export default Welcome

const Title = styled(DigestiveText)`
    font-size: 150px;

    @media (max-width: 600px) {
        font-size: 100px;
    }
`
const MotionDiv = styled(motion.div)``
