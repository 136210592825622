import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Container, Button } from './reusables';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { contentType, nameField, emailField } from '../../state/atoms';

const Heading = styled.p`
    font-family: "Montserrat";
    font-size: 20px; 
    margin-bottom: 50px;

    @media (max-width: 550px) {
        font-size: 18px;
    }
`;
const ButtonGroup = styled.div`
    margin-bottom: 30px;
`;
const Highlight = styled.span`
    color: #ffd7d7;
`;
const BlackoutIntro = ({ setStep }) => {
    
    const [type, setType] = useRecoilState(contentType)

    // useEffect(() => {
    //     alert("Intro")
    // }, [])

    useEffect(() => {
        if (type !== '') {
            setStep('start')
        }
    }, [type])

    return (
        <Container
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
        >
            <Heading>The most difficult part of learning is often<br /><br />"WHERE DO I BEGIN?"<br /><br /><Highlight>Let's help, what are you phealing to do?</Highlight></Heading>
            <ButtonGroup>
                <Button style={{color: '#d7fffd'}} color={'dark'} onClick={() => setType('Video')} >watch</Button>
                <Button style={{color: '#f1ffd7'}} color={'dark'} onClick={() => setType('Read')} >read</Button>
            </ButtonGroup>
        </Container>
    )
}

export default BlackoutIntro