import React, { useState, useEffect } from "react"
import styled from "styled-components"
import BlackoutIntro from "../components/blackout/intro"
import ContactInfo from "../components/blackout/contact-info"
import Start from "../components/blackout/start"
import Result from "../components/blackout/result"
import { AnimatePresence } from "framer-motion"
import { useRecoilState, useSetRecoilState } from "recoil"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql, navigate } from "gatsby"
import { contentType, colorScheme } from "../state/atoms"
import { fadeDuration } from "../../project-constants"
import useLocalStorage from "../components/custom-hooks/useLocalStorage"
import BlackoutHome from "../components/blackout/home"
import { DigestiveText } from "../styles/typography"
import WalkthroughIntro from "../components/blackout/walkthrough-intro"

const BlackoutResourcesPage = ({ data }) => {
    const [entered, setEntered] = useLocalStorage("entered", "")

    //TURN ON FOR LIVE
    const [activeStep, setActiveStep] = useState("intro")
    // const [activeStep, setActiveStep] = useState('contact');
    const setType = useSetRecoilState(contentType)
    const [color, setColor] = useRecoilState(colorScheme)
    const palette = ["#fff3d7", "#ffd7d7", "#d7fffd", "#f1ffd7", "#d7ffda"]
    const [bgColor, setBgColor] = useState("black")

    useEffect(() => {
        if (activeStep === "result") {
            const randomPalette =
                palette[Math.floor(Math.random() * palette.length)]
            setBgColor(randomPalette)
        }
    }, [color])

    useEffect(() => {
        setColor("dark")
    }, [])

    const handleReset = () => {
        navigate("/how-it-works")
        setType("")
        setColor("dark")
        setBgColor("black")
        setActiveStep("intro")
    }

    return (
        <Layout>
            <Container
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                color={bgColor}
            >
                <SEO title="Blackout Resources" />
                {activeStep !== "intro" && (
                    <Title onClick={() => handleReset()} color={color}>
                        PHEAL
                    </Title>
                )}
                <AnimatePresence>
                    {activeStep === "home" && (
                        <BlackoutHome setStep={setActiveStep} />
                    )}
                    {/* {activeStep === "contact" && (
                        <ContactInfo
                            quotes={data.quotes.edges}
                            setStep={setActiveStep}
                        />
                    )} */}
                    {activeStep === "intro" && (
                        <WalkthroughIntro setActiveStep={setActiveStep} />
                    )}
                    {activeStep === "start" && (
                        <Start setStep={setActiveStep} />
                    )}
                    {activeStep === "result" && (
                        <Result
                            resources={data.allGoogleAllSheet.edges}
                            setBgColor={setBgColor}
                            setColor={setColor}
                            setStep={setActiveStep}
                        />
                    )}
                </AnimatePresence>
                {activeStep !== "home" && activeStep !== "start" && (
                    <TryAgain color={color} onClick={() => navigate("/")}>
                        Go home
                    </TryAgain>
                )}
            </Container>
        </Layout>
    )
}

export default BlackoutResourcesPage

export const sheetQuery = graphql`
    query SheetQuery {
        allGoogleAllSheet {
            edges {
                node {
                    name
                    link
                    type
                    note
                }
            }
        }
        quotes: allGoogleQuotesSheet {
            edges {
                node {
                    author
                    quote
                }
            }
        }
    }
`

const Container = styled.div`
    background: ${props => props.color};
    transition: ${fadeDuration}s ease;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
`
const Title = styled(DigestiveText)`
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;
    font-size: 120px;
    font-weight: normal;
    /* letter-spacing: -5px; */
    text-align: center;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
`

const TryAgain = styled.button`
    background: none;
    border: none;
    outline: none;
    opacity: 0.5;
    font-family: "Montserrat";
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;
    cursor: pointer;
    margin-bottom: 30px;
`
