import React from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, TextWrapper } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { BodyText, DigestiveText } from "../../styles/typography"

const Channels = () => {
    return (
        <ContentWrapper>
            <Flex
                style={{
                    position: "relative",
                    height: "100%",
                    color: colors.yellow,
                    paddingTop: 100,
                    paddingBottom: 100,
                    scrollSnapAlign: "start",
                }}
                align="flex-end"
                justify="flex-start"
                direction="column"
            >
                <Title style={{ textAlign: "right" }} mBottom={30}>
                    Channels
                </Title>
                <TextWrapper>
                    <BodyText style={{ textAlign: "right" }}>
                        Once you sign up as a collaborator, you’re given access
                        to your personal PHEAL channel which acts as your main
                        profile. Your channel comes with basic access to an
                        individual resource archive that you can categorize and
                        search by topic or subject. Collaborators can also
                        upload original media content to their channel whether
                        it be text, image, audio, video or a combination.
                        Depending on your subscription package, you can access
                        additional features to customize your channel.
                    </BodyText>
                </TextWrapper>
            </Flex>
        </ContentWrapper>
    )
}

export default Channels

const Title = styled(DigestiveText)`
    font-size: 120px;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`
