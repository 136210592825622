import React, { useContext } from "react"
import Loader from "react-loader-spinner"
import styled from "styled-components"
import Layout from "../components/layout"
import PageHeading from "../components/page-heading"
import PrivateRoute from "../components/private-route"
import ViewReadWatch from "../components/profile/view-read-watch"
import SEO from "../components/seo"
import { ContentWrapper, Flex, Wrapper, TextWrapper } from "../layout/reusables"
import { FirebaseContext } from "../services"
import { BodyCopy } from "../styles/typography"

const Resources = () => {
    const { initialized, user } = useContext(FirebaseContext)

    return (
        <Layout dark>
            <SEO title="Your Collection" />
            <PrivateRoute path="/auth">
                <Wrapper>
                    <ContentWrapper>
                        {initialized ? (
                            <>
                                <PageHeading title="Your Read/Watch Resources" />
                                {/* <TextWrapper style={{ marginBottom: 80 }}>
                                    <BodyCopy>
                                        These are the links that your audience
                                        will see when they choose to view a
                                        random resource from your channel.
                                    </BodyCopy>
                                </TextWrapper> */}
                                {user && <ViewReadWatch userId={user?.uid} />}
                            </>
                        ) : (
                            <Flex align="center" justify="center">
                                <Loader
                                    type="Circles"
                                    width={50}
                                    height={50}
                                    color="black"
                                />
                            </Flex>
                        )}
                    </ContentWrapper>
                </Wrapper>
            </PrivateRoute>
        </Layout>
    )
}

export default Resources

const Grid = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 20px;
    @media (max-width: 700px) {
        grid-template-columns: 100%;
    }
`
