import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, Wrapper } from "../layout/reusables"
import "./write.css"
import SEO from "../components/seo"

import { MainButton, OutlineButton } from "../styles/buttons"
import { FirebaseContext, originalContentRef } from "../state/FirebaseProvider"
import Layout from "../components/layout"
import PrivateRoute from "../components/private-route"
import PageHeading from "../components/page-heading"
import EmptyState from "../components/create/empty-state"
import ContentTypeBar from "../components/create/content-type-bar"
import {
    ARTICLE_TYPE,
    AUDIO_TYPE,
    contentTypes,
    PHOTO_TYPE,
    VIDEO_TYPE,
} from "../components/create/content-types"
import ArticleContent from "../components/create/article-content"
import PhotoContent from "../components/create/photo-content"
import VideoContent from "../components/create/video-content"
import AudioContent from "../components/create/audio-content"

const CreatePage = () => {
    const { user, _serverTimestamp, firebase } = useContext(FirebaseContext)

    const [saving, setSaving] = useState(false)
    const [contentType, setContentType] = useState(null)

    const handleSave = () => {
        //This will manage saving in the different content types.
    }

    return (
        <Layout dark>
            <SEO title="Create" />
            <PrivateRoute path="/auth">
                <Wrapper>
                    <ContentWrapper
                    >
                        <PageHeading title="Original Content" />
                        {!contentType &&
                            <ContentTypeBar
                                contentType={contentType}
                                setContentType={setContentType}
                            />
                        }
                        {!contentType && <EmptyState />}
                        {contentType === ARTICLE_TYPE && (
                            <ArticleContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                            />
                        )}
                        {contentType === PHOTO_TYPE && 
                            <PhotoContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                            />
                        }
                        {contentType === VIDEO_TYPE && 
                            <VideoContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                            />
                        }
                        {contentType === AUDIO_TYPE && 
                            <AudioContent
                                user={user}
                                firebase={firebase}
                                _serverTimestamp={_serverTimestamp}
                            />
                        }
                    </ContentWrapper>
                </Wrapper>
            </PrivateRoute>
        </Layout>
    )
}

export default CreatePage

const Toolbar = styled.div`
    position: fixed;
    background: white;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid #eee;
    z-index: 2;
`
