import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Container } from './reusables';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { nameField, emailField, contentType } from '../../state/atoms';
import { motion, AnimatePresence } from 'framer-motion';
import useInterval from '../custom-hooks/useInterval';

const Heading = styled.p`
    font-weight: 700;
    font-size: 16px;
`;
const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    margin-bottom: 15px;
    max-width: 650px;
    width: 100%;
`;
const Input = styled.input`
    border: 3px solid white;
    background: none;
    color: white;
    width: 100%;
    min-height: 70px;
    font-size: 18px;
    margin-bottom: 20px;
    padding: 0 15px 0 30px;

    @media (max-width: 600px) {
        font-size: 16px;
        padding: 0 15px 0 15px;

    }
`;
const Copy = styled.p`
    max-width: 500px;
    opacity: 0.7;
    font-size: 14px;
    text-align: left;
`;
const InputField = styled.div`
    width: 100%;
    position: relative;
    align-items: center;
    height: 70px;
`;
const ContinueButton = styled.button`
    position: absolute;
    right: 15px;
    font-weight: 900;
    top: 25%;
    color: white;
    cursor: pointer;
    background: none;
    font-size: 14px;
    transition: 0.3s ease-out;
    border: none;
    outline: none;
    padding: 5px 10px;
    text-transform: uppercase;
    opacity: 1;


    :disabled {
        opacity: 0.1;
    }
`;

const Quote = styled(motion.blockquote)`
    font-family: "Montserrat";
    font-style: italic;
    opacity: 0.5;
    font-size: 22px;
    color: #fff3d7;
    max-width: 350px;
    margin: 0 auto;
    line-height: 1.2;
    margin-bottom: 30px;
    /* opacity: 0.6; */
    min-height: 100px;

`;
const CTA = styled.p`
    font-size: 14px;
    max-width: 200px;
    font-size: 600;
    margin: 0 auto;
    margin-bottom: 30px;
    text-decoration: underline;
`;
const Highlight = styled.span`
    color: #ffd7d7;
`;

const ContactInfo = ({ setStep, quotes }) => {
    

    const [nameText, setNameText] = useState('')
    const [emailText, setEmailText] = useState('')
    const [quote, setQuote] = useState(null)
    const [index, setIndex] = useState(0)

    const [name, setName] = useRecoilState(nameField)
    const [email, setEmail] = useRecoilState(emailField)
    const setType = useSetRecoilState(contentType)

    useEffect(() => {
        setRandomQuote()
    }, [])

    useInterval(() => {
        setRandomQuote()
    }, 7000)
    useInterval(() => {
        setQuote("exit")
    }, 6900)

    const setRandomQuote = () => {
        if (index === quotes.length - 1) {
            setIndex(0)
            setQuote(quotes[0])
            return
        }

        setQuote(quotes[index])
        setIndex(index + 1)
    }


    const disabled = () => {
        if (nameText === "" || emailText === "") {
            return true
        }
        return false
    }

    return (
        <Container
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            // positionTransition
            transition={{
                height: "linear", stiffness: 50
            }}
            exit={{opacity: 0}}
        >
            {/* <Heading>PEOPLE HERE EVOLVE A LOT</Heading> */}
            <AnimatePresence>
                {quote && quote != "exit" && <Quote positionTransition key={quote} exit={{opacity: 0, x: -10}} initial={{opacity: 0, x: 10}} animate={{opacity: 1, x: 0}} transition={{duration: 1, type: "spring", stiffness: 50 }}>{quote.node.quote}</Quote>}

            </AnimatePresence>
            <CTA>If you're <Highlight>phealing</Highlight> to learn, enter your name and email</CTA>
            <InputGroup>
                <Input placeholder="James" value={nameText} onChange={(e) => setNameText(e.target.value)} />
                <InputField>
                    <Input style={{paddingRight: 75}} placeholder="james@email.com" value={emailText} onChange={(e) => setEmailText(e.target.value)} />
                    <ContinueButton disabled={disabled()} onClick={() => {
                        setName(nameText)
                        setEmail(emailText)
                        setType('')
                        setStep('intro')
                        }}>Next</ContinueButton>
                </InputField>
            </InputGroup>
            <Copy>Your email address will not be shared with anyone.</Copy>
        </Container>
    )
}

export default ContactInfo