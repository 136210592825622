import React from "react"
import styled from "styled-components"
import { ContentWrapper, Flex } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { DigestiveText } from "../../styles/typography"

const Container = styled.section`
    background: ${colors.yellow};
    color: black;
    text-align: center;
    padding: 10vh 20px;
`
const Content = styled(Flex)``
const Subtitle = styled.p`
    margin-bottom: 50px;
`
const Prompt = styled(DigestiveText)`
    font-size: clamp(60px, 7vw, 200px);
    margin-bottom: 50px;
`
const Actions = styled.div``

const ReadWatchSection = () => {
    return (
        <Container>
            <ContentWrapper>
                <Content direction="column" align="center">
                    <Subtitle>Lorem Ipsum Dolor</Subtitle>
                    <Prompt noUppercase>How do you pheal to learn?</Prompt>
                    <Actions>
                        <MainButton>Watch</MainButton>
                        <MainButton>Read</MainButton>
                    </Actions>
                </Content>
            </ContentWrapper>
        </Container>
    )
}

export default ReadWatchSection
