import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useState } from "react"
import { useMedia, useWindowScroll } from "react-use"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import Menu from "./menu"
import { menuOpen } from "../state/atoms"
import { useRecoilState } from "recoil"

const Container = styled(motion.nav)`
    padding: ${props => (props.scrolled ? "8px 20px" : "12px 20px")};
    background: ${props =>
        props.scrolled ? (props.dark ? "black" : "white") : "transparent"};
    position: fixed;
    z-index: 99;
    transition: 0.2s ease;
    right: 0;
    left: 0;
    top: 0;
    color: ${props =>
        props.dark
            ? props.scrolled
                ? "white"
                : "black"
            : props.scrolled
            ? "black"
            : "white"} !important;
`
const Logo = styled.img`
    max-width: 100px;
    flex: 1;
    @media (max-width: 550px) {
        max-width: 80px;
    }
`
const Nav = styled.nav`
    display: flex;
    /* grid-template-columns: auto auto 1fr auto auto; */
    grid-template-columns: 1fr;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
`
const LinkItem = styled(Link)`
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Haas";
    text-decoration: none;
    text-align: center;
    color: inherit;
    white-space: nowrap;
    transition: 0.2s ease;
    :hover {
        text-decoration: underline;
    }
    @media (max-width: 550px) {
        font-size: 12px;
    }
`
const Burger = styled.div`
    cursor: pointer;
`

const MobileNavbar = ({ dark, animate }) => {
    const [isMenuOpen, toggleMenu] = useRecoilState(menuOpen)

    const isWide = useMedia("(min-width: 550px)")
    const { x, y } = useWindowScroll()

    const scrolled = y > 40

    return (
        <Container
            dark={dark}
            scrolled={scrolled}
            initial={{ y: animate ? "-200%" : 0, opacity: animate ? 0 : 1 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2, duration: 1, ease: "easeOut" }}
        >
            <Nav>
                <div style={{ width: 23 }} />
                <Link to="/" style={{ textAlgin: "center" }}>
                    {isWide ? (
                        <Logo
                            src={require(`../images/Pheal-Logo-${
                                dark
                                    ? scrolled
                                        ? "White"
                                        : "Black"
                                    : scrolled
                                    ? "Black"
                                    : "White"
                            }.svg`)}
                        />
                    ) : (
                        <Logo
                            src={require(`../images/Pheal-Logo-${
                                dark
                                    ? scrolled
                                        ? "White"
                                        : "Black"
                                    : scrolled
                                    ? "Black"
                                    : "White"
                            }-S.svg`)}
                        />
                    )}
                </Link>
                <Burger>
                    <HamburgerMenu
                        isOpen={isMenuOpen}
                        menuClicked={() => toggleMenu(!isMenuOpen)}
                        width={23}
                        height={15}
                        strokeWidth={2}
                        rotate={0}
                        color={
                            dark
                                ? scrolled
                                    ? "white"
                                    : "black"
                                : scrolled
                                ? "black"
                                : "white"
                        }
                        borderRadius={0}
                        animationDuration={0.5}
                    />
                </Burger>
            </Nav>
        </Container>
    )
}

export default MobileNavbar