import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useState } from "react"
import useInView from "react-cool-inview"
import styled from "styled-components"
import { ContentWrapper, Flex, TextWrapper } from "../../layout/reusables"
import { SIGN_UP_FORM_URL } from "../../pages/how-it-works"
import { BodyText, DigestiveText } from "../../styles/typography"

const FeatureSection = ({
    title,
    copy,
    textColor,
    align,
    ctaCopy,
    to,
    newTab,
}) => {
    const [visible, setVisible] = useState(false)

    const { ref, inView } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            setVisible(true)
        },
    })

    return (
        <ContentWrapper ref={ref}>
            <Flex
                style={{
                    position: "relative",
                    height: "100%",
                    color: textColor ?? "white",
                    textAlign: align ?? "left",
                    paddingTop: 100,
                    paddingBottom: 100,
                    scrollSnapAlign: "start",
                }}
                align={align === "right" ? "flex-end" : "flex-start"}
                justify="flex-start"
                direction="column"
            >
                <Title
                    initial={{ opacity: 0, y: "60%" }}
                    animate={{
                        opacity: visible ? 1 : 0,
                        y: visible ? 0 : "100%",
                    }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    mBottom={30}
                >
                    {title}
                </Title>
                <TextWrapper>
                    <BodyText
                        initial={{ opacity: 0, y: "60%" }}
                        animate={{
                            opacity: visible ? 1 : 0,
                            y: visible ? 0 : "100%",
                        }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        style={{ marginBottom: 30 }}
                    >
                        {copy}
                    </BodyText>
                    {to ? (
                        <CTA
                            to={to}
                            as={Link}
                            target="_blank"
                            rel="no referrer"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: visible ? 1 : 0,
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                delay: 1,
                            }}
                        >
                            {ctaCopy}
                        </CTA>
                    ) : (
                        <CTA
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: visible ? 1 : 0,
                            }}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                delay: 1,
                            }}
                            href={SIGN_UP_FORM_URL}
                            target="_blank"
                            rel="no referrer"
                        >
                            {ctaCopy}
                        </CTA>
                    )}
                </TextWrapper>
            </Flex>
        </ContentWrapper>
    )
}

export default FeatureSection

const Title = styled(DigestiveText)`
    font-size: 120px;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`
export const CTA = styled(motion.a)`
    text-decoration: underline;
    /* color: white; */
    color: inherit;
    font-weight: 500;
    font-family: "Haas";
    font-size: 20px;
`
