// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-channels-js": () => import("./../../../src/pages/channels.js" /* webpackChunkName: "component---src-pages-channels-js" */),
  "component---src-pages-collaborator-js": () => import("./../../../src/pages/collaborator.js" /* webpackChunkName: "component---src-pages-collaborator-js" */),
  "component---src-pages-create-js": () => import("./../../../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-edit-js": () => import("./../../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-original-content-all-js": () => import("./../../../src/pages/original-content/all.js" /* webpackChunkName: "component---src-pages-original-content-all-js" */),
  "component---src-pages-original-content-index-js": () => import("./../../../src/pages/original-content/index.js" /* webpackChunkName: "component---src-pages-original-content-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-write-js": () => import("./../../../src/pages/write.js" /* webpackChunkName: "component---src-pages-write-js" */)
}

