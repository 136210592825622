import firebase from "gatsby-plugin-firebase"

const readWatchRef = firebase.firestore().collection("read-watch")

export const AddReadWatchItem = data => {
    return readWatchRef.add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
}
