import { graphql, Link, navigate } from "gatsby"
import React, { useCallback, useContext, useState } from "react"
import Loader from "react-loader-spinner"
import styled from "styled-components"
import Layout from "../components/layout"
import { ContentWrapper, Flex, TextWrapper, Wrapper } from "../layout/reusables"
import { FirebaseContext } from "../services"
import { RegisterUser } from "../services/auth"
import {
    collaboratorAccessRef,
    collaboratorsRef,
} from "../state/FirebaseProvider"
import { MainButton } from "../styles/buttons"
import { BodyCopy, DigestiveText } from "../styles/typography"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import HouseRules from "../components/house-rules"
import LoginIllustration from "../components/empty-states/login-illustration"
import PhealLoader from "../components/pheal-loader"
import ForgotPassword from "../components/auth/forgot-password"
import SEO from "../components/seo"

const AuthPage = ({ data }) => {
    const { firebase, authToken, setAuthToken } = useContext(FirebaseContext)

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [unapproved, setUnapproved] = useState(false)
    const [showHouseRules, setShowHouseRules] = useState(false)
    const [showingPass, setShowingPass] = useState(false)

    const handleSignUp = () => {
        RegisterUser(email, password, onAuthenticated)
    }

    // Method for signing up and logging in.
    const handleSignupAndLogin = useCallback(async () => {
        setLoading(true)
        let authError
        try {
            //First, we need to make sure this user has been given access to
            const accessSnapshot = await collaboratorAccessRef
                .where("email", "==", email)
                .get()
            if (accessSnapshot.empty) {
                // alert(
                //     "Sorry, sign up is available to pre-approved collaborators only!"
                // )
                setUnapproved(true)
                setLoading(false)
                return
            }
            const {
                user,
            } = await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)

            // If successful and we have a user the set the authToken.
            if (user) {
                const { refreshToken, uid } = user
                setAuthToken(refreshToken)
                onAuthenticated(uid)
            }

            //Create a new collaborator
            await collaboratorsRef.doc(user.uid).set({
                email,
                id: user.uid,
            })
            // If there is an error set the authError to the new error
        } catch (error) {
            authError = error
            setLoading(false)
            if (authError?.code !== "auth/email-already-in-use") {
                alert(authError?.message)
            }
        } finally {
            // If there is an authError and the code is that the email is already in user try to sign the user
            // in with the email and password instead.
            if (authError?.code === "auth/email-already-in-use") {
                const {
                    user,
                } = await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                // We’ve been here before… set the authToken if there is a user.
                if (user) {
                    const { refreshToken } = user
                    setAuthToken(refreshToken)
                    onAuthenticated(user.uid)
                    setLoading(false)
                }
            } else {
            }
        }
    }, [email, password, firebase, setAuthToken])

    const onAuthenticated = async userId => {
        //Get the user and see what they have set up so far
        const collaborator = await collaboratorsRef.doc(userId).get()
        const collaboratorData = collaborator.data()

        if (collaboratorData && collaboratorData.name) {
            navigate("/")
            return
        }

        //Make sure house rules are available
        if (!houseRules) {
            navigate("/profile")
            return
        }

        setShowHouseRules(true)
    }

    const { houseRules } = data

    return (
        <Layout dark>
            <SEO title="Login" />
            <Wrapper>
                <ContentWrapper>
                    <Grid
                        style={{ zIndex: 1 }}
                        direction="row"
                        align="center"
                        justify="center"
                        positionTransition={true}
                    >
                        <LoginIllustration />
                        <div>
                            <DigestiveText mBottom={20}>
                                Log in to Continue
                            </DigestiveText>
                            <TextWrapper>
                                <BodyCopy
                                    mBottom={20}
                                    style={{
                                        fontSize: 16,
                                        opacity: 0.9,
                                        maxWidth: 400,
                                    }}
                                >
                                    First time logging in? — Enter an email
                                    address and password to get started, we’ll
                                    get your account set up.
                                </BodyCopy>
                            </TextWrapper>
                            <Input
                                type="email"
                                value={email}
                                name="email"
                                placeholder="you@email.com"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Input
                                type={showingPass ? "text" : "password"}
                                value={password}
                                name="password"
                                placeholder="Password"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <ShowPassword
                                onClick={() => setShowingPass(!showingPass)}
                            >
                                {showingPass ? 'Hide' : "Show"} Password
                            </ShowPassword>
                            <BodyCopy
                                mBottom={15}
                                style={{ fontSize: 14, opacity: 0.6 }}
                            >
                                By continuing, you are agreeing to our{" "}
                                <Link stye={{ color: "black" }} to="/terms">
                                    Terms of Service
                                </Link>
                            </BodyCopy>
                            <Flex
                                direction="row"
                                align="center"
                                style={{ marginBottom: 15 }}
                            >
                                <MainButton
                                    style={{ marginRight: 15 }}
                                    dark
                                    disabled={!email || !password}
                                    onClick={() => handleSignupAndLogin()}
                                >
                                    Continue
                                </MainButton>

                                {loading && <PhealLoader />}
                            </Flex>
                            <Forgot onClick={() => setShowPasswordReset(true)}>
                                Forgot your password?
                            </Forgot>
                            {unapproved && (
                                <Card>
                                    <CardContent style={{ maxWidth: 400 }}>
                                        <BodyCopy style={{ fontSize: 15 }}>
                                            Only pre-approved collaborator
                                            accounts are allowed right now.
                                        </BodyCopy>
                                    </CardContent>
                                    <CardActions>
                                        <MainButton
                                            onClick={() =>
                                                navigate("/how-it-works")
                                            }
                                        >
                                            Learn More
                                        </MainButton>
                                    </CardActions>
                                </Card>
                            )}
                            <ForgotPassword
                                open={showPasswordReset}
                                setOpen={setShowPasswordReset}
                                firebase={firebase}
                            />
                            {houseRules && (
                                <HouseRules
                                    data={houseRules}
                                    open={showHouseRules}
                                    setOpen={setShowHouseRules}
                                />
                            )}
                        </div>
                    </Grid>
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export default AuthPage

export const HouseRulesQuery = graphql`
    query houseRulesQuery {
        houseRules: wpPost(id: { eq: "cG9zdDo2" }) {
            title
            content
            id
        }
    }
`
const Grid = styled(Flex)`
    @media (max-width: 650px) {
        flex-direction: column;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`
const Input = styled.input`
    background-color: #f3f3f3;
    border-radius: 3px;
    font-size: 18px;
    padding: 15px 20px;
    width: 100%;
    width: -webkit-fill-available;
    max-width: 400px;
    margin-bottom: 15px;
    font-family: "Haas";
    border: none;
    outline: none;
`
const Forgot = styled.button`
    margin: 0;
    margin-top: 10px;
    display: block;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    color: #333;
    background: none;
`
const ShowPassword = styled.button`
    margin: 0;
    margin-bottom: 20px;
    display: block;
    padding: 0;
    font-size: 14px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #3b3b3b;
    background: none;
`
