import React, { useState } from "react"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { readwatchRef } from "../../state/FirebaseProvider"

const DeleteDialog = ({ open, setOpen, id }) => {
    const handleClose = () => {
        setOpen(false)
    }

    const handleDelete = async () => {
        try {
            await readwatchRef.doc(id).delete()
            setOpen(false)
        } catch (err) {
            console.log(err.message)
            alert("We couldn't delete your Read/Watch, please try again")
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText
                    style={{ fontFamily: "Haas", color: "black" }}
                    id="alert-dialog-description"
                >
                    Delete this Read/Watch from your collection?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleDelete()}
                    color="primary"
                    autoFocus
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog
