import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import Loader from "react-loader-spinner"
import { useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"
import { FirebaseContext } from "../services"
import { globalUser, sidebarOpen } from "../state/atoms"
import { originalContentRef, readwatchRef } from "../state/FirebaseProvider"
import { Heading } from "../styles/typography"
import EmptySidebar from "./empty-states/empty-sidebar"
import OriginalContentItem from "./original-content-item"
import ResourceItem from "./resource-item"

const ResourceList = () => {
    const user = useRecoilValue(globalUser)
    const toggleSidebar = useSetRecoilState(sidebarOpen)

    const originalContentQuery = originalContentRef
        .where("collaboratorId", "==", user)
        .orderBy("createdAt", "desc")

    const resourcesQuery = readwatchRef
        .where("collaboratorId", "==", user)
        .orderBy("createdAt", "desc")

    const [originalContent, loading, error] = useCollection(
        originalContentQuery
    )
    const [readWatchContent, loadingReadWatch, readWatchError] = useCollection(
        resourcesQuery
    )
    if (originalContent) console.log(originalContent.docs)
    if (readWatchContent) console.log(readWatchContent.docs)

    return (
        <Container>
            <Heading mBottom={30}>Your Resources</Heading>
            {loading && <Loader type="Circles" height={30} width={30} />}
            {/* <Heading style={{ fontSize: 22 }} mBottom={30}>
                Original Content
            </Heading> */}
            {originalContent &&
                originalContent.docs.map((content, index) => (
                    <OriginalContentItem
                        item={{ id: content.id, ...content.data() }}
                    />
                ))}
            {readWatchContent &&
                readWatchContent.docs.map((content, index) => (
                    <ResourceItem
                        item={{ id: content.id, ...content.data() }}
                    />
                ))}
            {!readWatchContent ||
                (!readWatchContent.docs.length && (
                    <EmptySidebar
                        clickHandler={() => {
                            navigate("/")
                            toggleSidebar(false)
                        }}
                        user={user}
                    />
                ))}
        </Container>
    )
}

export default ResourceList

const Container = styled.div`
    padding: 30px 0;
    padding-bottom: 120px;
`
