import { useSnackbar } from "notistack"
import React, { useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex } from "../../layout/reusables"
import { OutlineButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { BodyText, DigestiveText } from "../../styles/typography"
import PhealLoader from "../pheal-loader"
import PreviewResult from "./preview-result"

const Container = styled.section`
    background: ${colors.yellow};
    color: black;
    text-align: center;
    padding: 5vh 20px;
`
const Content = styled(Flex)`
    margin-bottom: 40px;
`
const Subtitle = styled(BodyText)`
    margin-bottom: 50px;
`
const Prompt = styled(DigestiveText)`
    font-size: clamp(60px, 7vw, 200px);
    margin-bottom: 50px;
`
const Actions = styled.div`
    button {
        margin: 5px 10px;
    }
`

const ReadWatchPreview = ({ collection }) => {
    const [resource, setResource] = useState(null)
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar()

    const failureAlert = () => {
        enqueueSnackbar(
            "Uh oh - You don't have any resources of this type yet.",
            {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                autoHideDuration: 3000,
            }
        )
    }

    const getRandomResource = type => {
        setLoading(true)
        setResource(null)
        const filteredResources = collection.filter(el => el.type === type)
        const randomResource =
            filteredResources[
                Math.floor(Math.random() * filteredResources.length)
            ]
        if (!randomResource) {
            failureAlert()
        }
        setTimeout(() => {
            setLoading(false)
            setResource(randomResource)
        }, 2500)
    }

    return (
        <Container>
            <ContentWrapper>
                <Content direction="column" align="center">
                    <Subtitle>
                        Try it for yourself! － This is how your audience will
                        access your Read/Watch resources
                    </Subtitle>
                    <Prompt noUppercase>How do you pheal to learn?</Prompt>
                    <Actions>
                        <OutlineButton
                            dark
                            onClick={() => getRandomResource("Watch")}
                        >
                            Watch
                        </OutlineButton>
                        <OutlineButton
                            dark
                            onClick={() => getRandomResource("Read")}
                        >
                            Read
                        </OutlineButton>
                    </Actions>
                </Content>
                {loading && <PhealLoader />}
                {resource && <PreviewResult result={resource} />}
            </ContentWrapper>
        </Container>
    )
}

export default ReadWatchPreview
