import React, { useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, TextWrapper } from "../../layout/reusables"
import { SIGN_UP_FORM_URL } from "../../pages/how-it-works"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { BodyText, DigestiveText } from "../../styles/typography"
import AddonItem from "./add-on-item"
import PricingItem from "./pricing-item"
import { CTA } from "./section"

export const SZN_PRICING = "Szn"
export const ANNUAL_PRICING = "Annual"

const MembershipAndPricing = () => {
    const [selectedCycle, setSelectedCycle] = useState(SZN_PRICING)

    return (
        <ContentWrapper>
            <Flex
                style={{
                    position: "relative",
                    height: "100%",
                    color: "white",
                    paddingTop: 100,
                    paddingBottom: 200,
                    scrollSnapAlign: "start",
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
            >
                <Title mBottom={30}>MEMEBERSHIP AND PRICING</Title>
                <TextWrapper style={{ marginBottom: 40 }}>
                    {/* <BodyText
                        style={{ fontWeight: 500 }}
                        fontSize={22}
                        mBottom={5}
                    >
                        Get started now, pick a plan later.
                    </BodyText> */}
                    <BodyText>
                        As one of our first collaborators at PHEAL, we’re
                        offering you exclusive access to our entire collaborator
                        suite to begin building your channel. All fees will be
                        waived from December 2020 to March 2021. Official
                        pricing on each package will be released during the
                        season.
                    </BodyText>
                    <a
                        href={SIGN_UP_FORM_URL}
                        target="_blank"
                        rel="no referrer"
                    >
                        <MainButton style={{ marginTop: 30 }}>
                            Get started now, pick a plan later
                        </MainButton>
                    </a>
                </TextWrapper>
                <hr style={{ width: "100%", opacity: 0.2, marginBottom: 40 }} />
                <Flex align="center" justify="center" style={{ width: "100%" }}>
                    <PerBox align="center" justify="space-between">
                        <PerItem
                            selected={selectedCycle === SZN_PRICING}
                            align="center"
                            justify="center"
                            onClick={() => setSelectedCycle(SZN_PRICING)}
                        >
                            <PerLabel selected={selectedCycle === SZN_PRICING}>
                                Per Szn
                            </PerLabel>
                        </PerItem>
                        <PerItem
                            selected={selectedCycle === ANNUAL_PRICING}
                            align="center"
                            justify="center"
                            onClick={() => setSelectedCycle(ANNUAL_PRICING)}
                        >
                            <PerLabel
                                selected={selectedCycle === ANNUAL_PRICING}
                            >
                                Per Year
                            </PerLabel>
                        </PerItem>
                    </PerBox>
                </Flex>
                <Flex direction="column" style={{ margin: "0 auto" }}>
                    <PricingItem
                        name="The Thinker"
                        sznPrice="0"
                        selectedCycle={selectedCycle}
                        annualPrice="0"
                        features={[
                            "Front facing website at pheal.me/your-brand",
                            "Share content from your favourite platforms",
                            "Channel display customization",
                        ]}
                    />
                    <PricingItem
                        name="The Savant"
                        selectedCycle={selectedCycle}
                        sznPrice="720"
                        annualPrice="1,680"
                        soon
                        features={["Upload original content & much more"]}
                        // features={[
                        //     "Front facing website at pheal.me/your-brand",
                        //     "Share content from your favourite platforms",
                        //     "Channel display customization",
                        //     "Channel comment section",
                        //     "Upload original content",
                        //     "Access emails for any user that enters your channel",
                        //     "Share your PHEAL content to your social media profiles",
                        //     "Allow users to share your PHEAL channel content to other social platforms",
                        // ]}
                    />
                    <BodyText
                        style={{
                            fontSize: 16,
                            textAlign: "center",
                            maxWidth: 400,
                            fontWeight: 500,
                            opacity: 0.8,
                        }}
                    >
                        If you have any further questions or you’d like to get
                        more information on PHEAL, feel free to reach out to us
                        at <u>hello@pheal.me</u>.
                    </BodyText>
                </Flex>
            </Flex>
        </ContentWrapper>
    )
}

export default MembershipAndPricing

const PerBox = styled(Flex)`
    border: 1px solid #0004a8;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.29);
    padding: 5px;
    width: 222px;
    height: 50px;
    margin-bottom: 40px;
`
const PerItem = styled(Flex)`
    background: ${props => (props.selected ? "white" : "transparent")};
    transition: 0.2s ease;
    border-radius: 8px;
    flex: 1;
    margin: 0 2px;
    height: 100%;
    cursor: pointer;
`
const PerLabel = styled(BodyText)`
    color: ${props => (props.selected ? colors.orange : "white")};
    padding: 0 10px;
`

const Title = styled(DigestiveText)`
    font-size: 120px;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`
