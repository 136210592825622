import React from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import { contentTypes } from "./content-types"

export default function ContentTypeBar({ contentType, setContentType }) {
    return (
        <Container>
            <Title>What type of content are you creating?</Title>
            <TypesContainer count={contentTypes.length}>
                {contentTypes.map((type, index) => (
                    <ContentTypeItem
                        key={index}
                        type={type}
                        selected={type === contentType}
                        clickHandler={setContentType}
                    />
                ))}
            </TypesContainer>
        </Container>
    )
}

const Container = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
    flex-direction: column;
    
`
const Title = styled.h5`
    margin-bottom: 35px;
    font-weight: 500;
    font-size: 20px;
`
const TypesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.count}, auto);
    gap: 20px;
    width: 100%;
    /* display: flex; */
    /* flex-direction: row; */

    @media (max-width: 550px) {
        overflow-x: scroll;
        justify-content: flex-start;
        gap: 10px;

    }
`

const ContentTypeItem = ({ selected, type, clickHandler }) => {
    return (
        <TypeContainer selected={selected} onClick={() => clickHandler(type)}>
            <Type selected={selected}>{type}</Type>
        </TypeContainer>
    )
}

const TypeContainer = styled.button`
    border: 1px solid #c6c6c6;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    background: ${props => (props.selected ? "black" : "transparent")};
    outline: none;
    cursor: pointer;
    transition: 0.2s ease-out;

    :hover {
        background: black;
    }
`
const Type = styled.p`
    font-weight: ${props => (props.selected ? 500 : 400)};
    color: ${props => (props.selected ? "white" : "#5F5F5F")};
    font-size: 17px;
    :hover {
        color: white;
    }
`
