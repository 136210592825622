import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex, Toolbar } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { Heading } from "../../styles/typography"
import { FaFileImage } from "react-icons/fa"
import firebase from "gatsby-plugin-firebase"
import { FirebaseContext } from "../../services"
import { collaboratorsRef } from "../../state/FirebaseProvider"
import Loader from "react-loader-spinner"
import CollaboratorHeader from "../collaborator/header"
import { useSnackbar } from "notistack"
import { slugifyString } from "../../utils/utils"
import { useLocalStorage } from "react-use"
import { navigate } from "gatsby"
import PhealLoader from "../pheal-loader"

const CollaboratorInformation = () => {
    const { authToken, userData, initialized, user } = useContext(FirebaseContext)

    const formRef = useRef()

    const [fileSelected, setFileSelected] = useState(false)
    const [collaboratorLogo, setCollaboratorLogo] = useState("")
    const [collaboratorLogoUrl, setCollaboratorLogoUrl] = useState("")
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [bio, setBio] = useState("")
    const [name, setName] = useState("")
    const [uploadCaptured, setUploadCaptured] = useState(false)

    //Custom hooks
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [firstTimeSetup, setFirstTimeSetup] = useLocalStorage(
        "firstTimeSetup"
    )

    useEffect(() => {
        if (userData) {
            setName(userData.name ?? "")
            setBio(userData.description ?? "")
            setCollaboratorLogoUrl(userData.logo ?? "")
        }
    }, [initialized, userData])

    const successAlert = () => {
        enqueueSnackbar("Updated!", {
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
            autoHideDuration: 3000,
        })
    }

    const createFlyer = e => {
        e.preventDefault()
        if (e.target.files[0]) {
            //Add the file to state
            setFileSelected(true)
            setCollaboratorLogo(e.target.files[0])
            setCollaboratorLogoUrl(URL.createObjectURL(e.target.files[0]))
            setUploadCaptured(true)
        } else {
            setFileSelected(false)
            setUploadCaptured(false)
        }
    }

    const onSubmit = ev => {
        ev.preventDefault()
        setLoading(true)
        const elementsArray = [...ev.target.elements]
        const formData = elementsArray.reduce((acc, elem) => {
            if (elem.id) {
                acc[elem.id] = elem.value
            }

            return acc
        }, {})

        //ONLY NEED TO WORRY ABOUT UPLOADING IF FILE INPUT WAS CAPTURED
        if (!uploadCaptured) {
            //Update the collaborator's profile
            delete formData.logo
            collaboratorsRef
                .doc(userData.id)
                .update({ ...formData })
                .then(res => {
                    setLoading(false)
                    successAlert()
                    if (firstTimeSetup) {
                        setFirstTimeSetup(true)
                        navigate("/")
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
            return
        }

        //Upload the image first, get the URL, then save collaborator
        //into firebase
        let uploadTask = firebase
            .storage()
            .ref()
            .child(`logos/${userData.id}/logo.png`)
            .put(collaboratorLogo)

        uploadTask.on(
            "state_changed",
            snapshot => {
                // Handle progress of upload
                let progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
                setProgress(progress)
            },
            error => {
                // Handle unsuccessful upload
                console.log("Something went wrong with flyer upload")
                setLoading(false)
            },
            () => {
                // Handle successful upload
                setProgress(100)
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                    console.log(
                        `Your uploaded image is now available at ${downloadURL}`
                    )

                    //Update the collaborator's profile
                    collaboratorsRef
                        .doc(userData.id)
                        .update({ ...formData, logo: downloadURL })
                        .then(res => {
                            console.log("Profile updated!")
                            setLoading(false)
                            successAlert()
                            if (firstTimeSetup) {
                                setFirstTimeSetup(true)
                                navigate("/")
                            }
                        })
                        .catch(err => {
                            setLoading(false)
                            console.log(err)
                        })
                })
            }
        )
    }

    return (
        <ContentWrapper
            style={{ marginTop: 50, padding: 0, paddingBottom: 50 }}
        >
            <Grid>
                <form
                    ref={formRef}
                    style={{ marginBottom: 50 }}
                    onSubmit={onSubmit}
                >
                    <Block>
                        <Label>Logo</Label>
                        <FieldFile
                            type="file"
                            name="logo"
                            id="logo"
                            onChange={createFlyer}
                        />
                        <FileLabel selected={fileSelected} for="logo">
                            <FaFileImage size={25} />{" "}
                            {fileSelected || collaboratorLogoUrl
                                ? "Uploaded"
                                : "Upload your logo"}
                        </FileLabel>
                        {/* <HelperText>
                            A square 800 x 800 logo is recommended
                        </HelperText> */}
                    </Block>
                    <Block>
                        <Label>Channel name</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            required
                            defaultValue={userData?.name}
                            onInput={e => setName(e.target.value)}
                            placeholder="eg. The Unforseen Heroes"
                        />
                        <HelperText>
                            Your channel will exist at pheal.me/
                            {userData?.name
                                ? slugifyString(userData.name)
                                : "your-channel-name"}
                        </HelperText>
                    </Block>

                    <Block>
                        <Label>Bio</Label>
                        <Textarea
                            type="text"
                            name="description"
                            id="description"
                            defaultValue={userData?.description}
                            maxLength={240}
                            rows={7}
                            required
                            onInput={e => setBio(e.target.value)}
                            placeholder="Tell us what you do! Keep it short, but informative."
                        />
                        {/* <HelperText>0/240</HelperText> */}
                    </Block>
                    <Flex>
                        <MainButton
                            style={{ marginRight: 20 }}
                            type="submit"
                            dark
                            disabled={loading}
                        >
                            Save Channel
                        </MainButton>
                        {loading && <PhealLoader />}
                    </Flex>
                </form>
                <Preview>
                    <Label>Your Channel Preview</Label>
                    <CollaboratorHeader
                        logo={collaboratorLogoUrl}
                        bio={bio}
                        name={name}
                    />
                    {/* <ReadWatchSection /> */}
                </Preview>
            </Grid>
        </ContentWrapper>
    )
}

export default CollaboratorInformation

const Label = styled.label`
    display: block;
    margin-bottom: 22px;
    font-size: 20px;
    font-weight: 500;
    font-family: "Haas";
`
const Input = styled.input`
    background-color: #f3f3f3;
    border-radius: 3px;
    font-size: 18px;
    padding: 15px 20px;
    /* width: 100%; */
    max-width: 400px;
    margin-right: 15px;
    font-family: "Haas";
    border: none;
    outline: none;
    margin-bottom: 10px;
`
const Textarea = styled.textarea`
    background-color: #f3f3f3;
    border-radius: 3px;
    font-size: 18px;
    padding: 15px 20px;
    max-width: 400px;
    margin-right: 15px;
    font-family: "Haas";
    border: none;
    outline: none;
    margin-bottom: 10px;
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
`
const HelperText = styled.p`
    font-size: 16px;
    color: #686868;
    font-weight: 500;
`
const FieldFile = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`

const FileLabel = styled.label`
    margin: 20px 0;
    font-size: 1em;
    font-weight: 400;
    color: black;
    font-family: "Haas", sans-serif;
    background-color: #f3f3f3;
    /* border: 1px solid black; */
    max-width: 200px;
    display: block;
    padding: 50px 20px;
    transition: 0.3s ease;
    cursor: pointer;
    :hover {
        background-color: #ddd;
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;

    @media (max-width: 950px) {
        grid-template-columns: 1fr;
    }
`
const Preview = styled.div`
    position: sticky;
    top: 0;
`
