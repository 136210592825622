import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentWrapper, Flex, TextWrapper, Wrapper } from "../layout/reusables"
import { Heading } from "../styles/typography"

const Content = styled.div`
    font-family: "Haas";
    line-height: 1.4;

    p {
        margin-bottom: 25px;
    }

    h6 {
        font-weight: 500;
        margin-bottom: 10px;
    }
    h5 {
        font-weight: 500;
        margin-bottom: 10px;
    }

    ul {
        margin-bottom: 25px;
    }
`

const TermsPage = ({ data }) => {
    const { terms } = data
    return (
        <Layout dark>
            <SEO title="Terms of Service" />
            <Wrapper>
                <ContentWrapper>
                    <Flex direction="column" align="center">
                        <TextWrapper>
                            <Heading fontSize={32}>Terms of Service</Heading>
                            <Content
                                dangerouslySetInnerHTML={{
                                    __html: terms.content,
                                }}
                            />
                        </TextWrapper>
                    </Flex>
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export default TermsPage

export const TermsQuery = graphql`
    query termsQuery {
        terms: wpPost(title: { eq: "Terms of Service" }) {
            title
            content
        }
    }
`
