import { navigate } from "gatsby"
import React, { useState } from "react"
import { useSetRecoilState } from "recoil"
import styled from "styled-components"
import { Flex } from "../layout/reusables"
import { sidebarOpen } from "../state/atoms"
import { Heading } from "../styles/typography"
import moment from "moment"
import { BsThreeDots } from "react-icons/bs"
import { Button, Popover } from "@material-ui/core"

export default function OriginalContentItem({ item }) {
    const toggleSidebar = useSetRecoilState(sidebarOpen)
    console.log(item)

    //Anchor element for popover
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    return (
        <>
            <Container
                onClick={() => {
                    // navigate(`/`, {
                    //     state: { content: item },
                    // })
                    // toggleSidebar(false)
                }}
            >
                <Title>{item.title}</Title>
                <Type>{item.type}</Type>
                <Tag justify="center" align="center">
                    {item.published ? "Published" : "Draft"}
                </Tag>
                <Description>{item.description}</Description>
                <Flex direction="row" align="center" justify="space-between">
                    <Timestamp>
                        {moment(item.createdAt.toDate())
                            .format("Do MMMM, YYYY")
                            .toString()}
                    </Timestamp>
                    <MoreBtn onClick={handleClick}>
                        <BsThreeDots size={27} />
                    </MoreBtn>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <PopoverContainer>
                            {!item.published && <MenuItem>Publish</MenuItem>}
                            <Button
                                onClick={() => {
                                    navigate(`/edit`, {
                                        state: { content: item },
                                    })
                                    toggleSidebar(false)
                                }}
                            >
                                Edit {item.type}
                            </Button>
                            <MenuItem>Delete {item.type}</MenuItem>
                        </PopoverContainer>
                    </Popover>
                </Flex>
            </Container>
            <hr style={{ opacity: 0.4, marginBottom: 30 }} />
        </>
    )
}

const Container = styled(Flex)`
    width: 100%;
    flex-direction: column;
`
const Title = styled(Heading)`
    font-size: 20px;
    margin-bottom: 5px;
`
const Type = styled.p`
    margin-bottom: 15px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 3px;
`
const Description = styled.p`
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 150%;
`
const Timestamp = styled.p`
    opacity: 0.7;
    margin-bottom: 20px;
`
const Tag = styled(Flex)`
    background: black;
    font-family: "Haas";
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    width: max-content;
    padding: 5px;
    color: white;
`
const MoreBtn = styled.button`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
`
const PopoverContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    gap: 5px;
    padding: 10px;
`
const MenuItem = styled(Button)`
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    color: #333;
`
