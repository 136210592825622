import React from "react"
import styled from "styled-components"
import CollaboratorHeader from "../components/collaborator/header"
import ReadWatchSection from "../components/collaborator/read-watch"
import { Wrapper } from "../layout/reusables"

const CollaboratorPage = () => {
    return (
        <Wrapper>
            <CollaboratorHeader />
            <ReadWatchSection />
        </Wrapper>
    )
}

export default CollaboratorPage
