import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import axios from "axios"
import { BodyCopy, Heading } from "../../styles/typography"
import DeleteDialog from "./delete-dialog"

const DisplayReadWatchItem = ({ item, index, setReadWatchItem, viewing }) => {
    const [open, setOpen] = useState(false)

    return (
        <RWItem
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            whileHover={{ scale: 1.01, y: -5 }}
            positionTransition
        >
            <Meta>
                <Flex justify="space-between" align="center">
                    <a
                        target="_blank"
                        rel="no referrer"
                        style={{ color: "inherit" }}
                        href={item.url}
                    >
                        <Title>{item.title}</Title>
                    </a>
                    <Tag justify="center" align="center">
                        {item.type}
                    </Tag>
                </Flex>
                <Description>{item.description}</Description>
                {viewing && (
                    <Remove onClick={() => setOpen(true)}>Remove</Remove>
                )}
            </Meta>
            <DeleteDialog setOpen={setOpen} open={open} id={item.id} />
        </RWItem>
    )
}

export default DisplayReadWatchItem

const RWItem = styled(motion.div)`
    border: 1px solid #c6c6c6;
    border-radius: 2px;
    padding: 15px;
    transition: 0.2s ease;
    cursor: pointer;

    :hover {
        border: 1px solid black;
    }
`
const Meta = styled.div``

const Title = styled(Heading)`
    font-size: 17px;
    margin-bottom: 3px;
`
const Description = styled(BodyCopy)`
    font-size: 16px;
    opacity: 0.8;
`
const Remove = styled.button`
    background: none;
    outline: none;
    color: red;
    border: none;
    margin-top: 15px;
    cursor: pointer;
`
const Tag = styled(Flex)`
    background: black;
    font-family: "Haas";
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px;
    color: white;
`
